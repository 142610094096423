import { Controller } from "stimulus";
import { slideUp, slideDown } from "./utils";

export default class extends Controller {
  static targets = [
    "other",
    "otherRequestingPhysician",
    "otherSample",
    "otherReferringHospital",
    "eightColorsSampleType",
    "fourColorsSampleType"
  ];

  handleOther(event) {
    let target = document.getElementById(event.target.dataset.div);
    if (["Otros"].includes(event.target.value)) {
      slideDown(target);
      this.otherTarget.setAttribute("required", "");
    } else {
      slideUp(target);
      this.otherTarget.removeAttribute("required");
      this.otherTarget.value = "";
    }
  }

  handleOtherRequestingPhysician(event) {
    let target = document.getElementById(event.target.dataset.div);
    if (["Otro"].includes(event.target.value)) {
      slideDown(target);
      this.otherRequestingPhysicianTarget.setAttribute("required", "");
    } else {
      slideUp(target);
      this.otherRequestingPhysicianTarget.removeAttribute("required");
      this.otherRequestingPhysicianTarget.value = "";
    }
  }

  handleOtherSample(event) {
    let target = document.getElementById(event.target.dataset.div);
    if (["Otro"].includes(event.target.value)) {
      slideDown(target);
      this.otherSampleTarget.setAttribute("required", "");
    } else {
      slideUp(target);
      this.otherSampleTarget.removeAttribute("required");
      this.otherSampleTarget.value = "";
    }
  }

  handleOtherReferringHospital(event) {
    let target = document.getElementById(event.target.dataset.div);
    if (["Otro"].includes(event.target.value)) {
      slideDown(target);
      this.otherReferringHospitalTarget.setAttribute("required", "");
    } else {
      slideUp(target);
      this.otherReferringHospitalTarget.removeAttribute("required");
      this.otherReferringHospitalTarget.value = "";
    }
  }

  handleSampleType(event) {
    if (event.target.value === 'Panel Inmunofenotipo 8 Colores') {
      this.fourColorsSampleTypeTarget.style.display = 'none'
      this.eightColorsSampleTypeTarget.style.display = 'block'
    } else {
      this.fourColorsSampleTypeTarget.style.display = 'block'
      this.eightColorsSampleTypeTarget.style.display = 'none'
    }
  }
}
