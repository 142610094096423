import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import esLocale from "@fullcalendar/core/locales/es";

const citasCalendar = () => {
  const calendarEl = document.getElementById("calendar");

  if (calendarEl) {
    let path = calendarEl.dataset.path;

    let calendar = new Calendar(calendarEl, {
      plugins: [dayGridPlugin, timeGridPlugin, listPlugin],
      headerToolbar: {
        left: "prev,next today",
        center: "title",
        right: "dayGridMonth,timeGridWeek,listWeek",
      },
      eventTimeFormat: {
        hour: "2-digit",
        hour12: true,
        minute: "2-digit",
      },
      initialView: "dayGridMonth",
      editable: true,
      events: path + ".json",
      locale: esLocale,
      dayMaxEventRows: 3,
      allDaySlot: false,
      lazyFetching: true,
      viewDidMount(arg) {
        let current_view = arg.view.type;
        if (window.innerWidth < 767.98) calendar.changeView("listWeek");
      },
      eventMouseEnter: function (event, el) {
        console.log("event >>: ");
      },
    });
    calendar.render();
  }
};

document.addEventListener("turbolinks:load", () => {
  citasCalendar();
});
