import { Controller } from "stimulus"
import { slideUp, slideDown } from './utils';

export default class extends Controller {
	static targets = ["otro"]

	handleOtro(event) {
		let targets = document.getElementsByClassName(event.target.dataset.div);

		if (["otro", "si"].includes(event.target.value)) {
      for (const target of targets) {
        slideDown(target)
      }
		} else {
      for (const target of targets) {
        slideUp(target)
      }
		}
	}

	handleOtros(event) {
		let target = document.getElementById(event.target.dataset.div);

		let values = Array.from(event.target.selectedOptions, option => option.value);

		if (values.includes("otro")) {
			slideDown(target);
			this.otroTarget.setAttribute("required", "");
		} else {
			slideUp(target);
			this.otroTarget.removeAttribute("required");
			this.otroTarget.value = ""
		}
	}
}
