import { Controller } from "stimulus"

export default class extends Controller {
	phoneNumberFormatter(event) {
		event.target.value = event.target.value
			.match(/\d*/g).join('')
		 	.match(/(\d{0,4})(\d{0,4})/).slice(1).join('-')
		  .replace(/-*$/g, '')
	}
}
