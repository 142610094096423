import { Controller } from "stimulus";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min.js";

export default class extends Controller {
  static targets = ["submit"];

  reportType(event) {
    let type = event.target.value;

    if (type.length > 0) {
      this.submitTarget.disabled = false;
    } else {
      this.submitTarget.disabled = true;
    }
  }

  onSubmit() {
    const my_fp = document.querySelector(".flatpickr-input")._flatpickr;
    const myModalEl = document.getElementById("reporte");
    const modal = bootstrap.Modal.getInstance(myModalEl);
    modal.hide();

    setTimeout(function () {
      my_fp.clear();
      document.getElementById("excel-report").reset();
    }, 8000);
  }
}
