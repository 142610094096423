import ApplicationController from "./application_controller";
import debounced from "debounced";

export default class extends ApplicationController {
  static targets = [
    "weight",
    "height",
    "gender",
    "age",
    "imc",
    "evaluationDate",
    "weightAgeIndex",
    "heightAgeIndex",
    "weightHeightIndex",
    "imcAgeIndex",
  ];

  connect() {
    super.connect();
    debounced.initialize({ input: { wait: 500 } });
  }

  async getWeightToAge() {
    const url = "/z_index/get_weight_to_age";
    const weight = Number(this.weightTarget.value);
    const gender = this.genderTarget.value;
    const age = this.getAgeInMonth(new Date(this.evaluationDateTarget.value));

    const response = await fetch(
      `${url}?weight=${weight}&gender=${gender}&age=${age}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const index = await response.json();
    this.weightAgeIndexTarget.value = this.calculateOption(index);
  }

  async getHeightToAge() {
    const url = "/z_index/get_height_to_age";
    const height = Number(this.heightTarget.value);
    const gender = this.genderTarget.value;
    const age = this.getAgeInMonth(new Date(this.evaluationDateTarget.value));

    const response = await fetch(
      `${url}?height=${height}&gender=${gender}&age=${age}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const index = await response.json();
    this.heightAgeIndexTarget.value = this.calculateOption(index);
  }

  async getWeightToHeight() {
    const url = "/z_index/get_weight_to_height";
    const height = Number(this.heightTarget.value);
    const weight = Number(this.weightTarget.value);
    const gender = this.genderTarget.value;
    const age = this.getAgeInMonth(new Date(this.evaluationDateTarget.value));

    const response = await fetch(
      `${url}?height=${height}&weight=${weight}&gender=${gender}&age=${age}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const index = await response.json();
    this.weightHeightIndexTarget.value = this.calculateOption(index);
  }

  async getImcToAge() {
    const url = "/z_index/get_imc_to_age";
    const imc = Number(this.imcTarget.value);
    const gender = this.genderTarget.value;
    const age = this.getAgeInMonth(new Date(this.evaluationDateTarget.value));

    const response = await fetch(
      `${url}?imc=${imc}&gender=${gender}&age=${age}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const index = await response.json();
    this.imcAgeIndexTarget.value = this.calculateOption(index);
  }

  getAgeInMonth(currentDate) {
    if (!currentDate) {
      currentDate = new Date();
    }

    const currentDateYear = currentDate.getFullYear();
    const currentDateMonth = currentDate.getMonth() + 1;
    const currentDateDay = currentDate.getDate();

    const fechaDeNacimientoYear = new Date(this.ageTarget.value).getFullYear();
    const fechaDeNacimientoMonth =
      new Date(this.ageTarget.value).getMonth() + 1;
    const fechaDeNacimientoDay = new Date(this.ageTarget.value).getDate();

    let ageInMonths =
      currentDateYear * 12 +
      currentDateMonth -
      (fechaDeNacimientoYear * 12 + fechaDeNacimientoMonth);

    if (currentDateDay < fechaDeNacimientoDay) {
      ageInMonths--;
    }

    return ageInMonths;
  }

  calculateOption(result) {
    switch (true) {
      case result === 999:
        return "No se puede calcular";
      case result > 3:
        return "+3";
      case result >= 2 && result <= 3:
        return "+3 a +2";
      case result >= 1 && result < 2:
        return "+2 a +1";
      case result >= 0 && result < 1:
        return "+1 a 0";
      case result >= -1 && result < 0:
        return "0 a -1";
      case result >= -2 && result < -1:
        return "-1 a -2";
      case result >= -3 && result < -2:
        return "-2 a -3";
      case result < -3:
        return "-3";
      default:
        return "0";
    }
  }
}
