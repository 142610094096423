import "choices.js/public/assets/scripts/choices.min.js";
import "choices.js/public/assets/styles/choices.min.css";
import { Controller } from "stimulus";
import { fadeIn, fadeOut } from "./utils";

let nombreDeEnfermedadChoices = null;
let histologiaChoices = null;

export default class extends Controller {
  static targets = [
    "diagnosticoInput",
    "nombreDeEnfermedadInput",
    "histologiaInput",
    "histologiaInputOtro",
  ];

  connect() {
    nombreDeEnfermedadChoices = new Choices(
      this.nombreDeEnfermedadInputTarget,
      {
        removeItems: true,
        removeItemButton: true,
      }
    );
    histologiaChoices = new Choices(this.histologiaInputTarget, {
      removeItems: true,
      removeItemButton: true,
    });

    const diagnostico = this.diagnosticoInputTarget.value;

    const selectedNombreDeEnfermedad =
      this.nombreDeEnfermedadInputTarget.dataset.nombredeenfermedad;

    const selectedHistologia = this.histologiaInputTarget.dataset.histologia;

    this.handleChildChoices(
      nombreDeEnfermedadChoices,
      this.nombreDeEnfermedadOptions(diagnostico, selectedNombreDeEnfermedad)
    );

    this.handleChildChoices(
      histologiaChoices,
      this.histologiaOptions(selectedNombreDeEnfermedad, selectedHistologia)
    );
  }

  disconnect() {
    nombreDeEnfermedadChoices.destroy();
    histologiaChoices.destroy();
  }

  handleDiagnosticoChange() {
    const diagnostico = this.diagnosticoInputTarget.value;
    const selectedNombreDeEnfermedad =
      this.nombreDeEnfermedadInputTarget.dataset.nombredeenfermedad;

    this.handleChildChoices(
      nombreDeEnfermedadChoices,
      this.nombreDeEnfermedadOptions(diagnostico, selectedNombreDeEnfermedad)
    );
  }

  handleNombreDeEnfermedadChange(event) {
    const nombreDeEnfermedad = this.nombreDeEnfermedadInputTarget.value;
    const selectedHistologia = this.histologiaInputTarget.dataset.histologia;

    this.handleChildChoices(
      histologiaChoices,
      this.histologiaOptions(nombreDeEnfermedad, selectedHistologia)
    );

    let histologiaInput = document.getElementById("histologia-input");
    let histologiaSelect = document.getElementById("histología-select");

    if (
      [
        "neoplasma_maligno_no_especificado",
        "otro_cáncer_benigno",
        "coagulación_secundaria_otros",
        "enfermedad_hematológica_no_especificada_de_otra_manera_(hv)",
        "carcinomas_otros_no_especificado",
        "otro_sarcoma_de_ewing",
        "otro_sarcoma_no_rabdomiosarcoma",
        "otros_tumores_varios_o_raros",
        "otro_cáncer_maligno",
        "tumores_óseos_benignos_otros",
        "tumores_óseos_malignos_otros",
        "otros_tumores_abdominales_o_mediastinal",
      ].includes(event.target.value)
    ) {
      fadeIn(histologiaInput);
      fadeOut(histologiaSelect);
    } else {
      fadeOut(histologiaInput);
      fadeIn(histologiaSelect);
      this.histologiaInputOtroTarget.value = "";
    }
  }

  handleChildChoices(choiceElement, choices) {
    choiceElement.clearChoices();
    choiceElement.setChoices(choices);
  }

  nombreDeEnfermedadOptions(diagnostico, selectedNombreDeEnfermedad) {
    let values;

    switch (diagnostico) {
      case "enfermedades_benignas":
        values = [
          {
            value: "masas_o_tumores_benignos",
            label: "Masas o Tumores Benignos",
            selected: selectedNombreDeEnfermedad === "masas_o_tumores_benignos",
          },
          {
            value: "neoplasma_maligno_no_especificado",
            label: "Neoplasma Maligno No Especificado",
            selected:
              selectedNombreDeEnfermedad ===
              "neoplasma_maligno_no_especificado",
          },
          {
            value: "otro_cáncer_benigno",
            label: "Otro Cáncer Benigno",
            selected: selectedNombreDeEnfermedad === "otro_cáncer_benigno",
          },
          {
            value: "hemangioma_benigno",
            label: "Hemangioma Benigno",
            selected: selectedNombreDeEnfermedad === "hemangioma_benigno",
          },
          {
            value: "sin_diagnóstico_confirmado",
            label: "Sin Diagnóstico Confirmado",
            selected:
              selectedNombreDeEnfermedad === "sin_diagnóstico_confirmado",
          },
        ];
        break;
      case "enfermedades_malignas_otros":
        values = [
          {
            value: "otro_cáncer_maligno",
            label: "Otro Cáncer Maligno",
            selected: selectedNombreDeEnfermedad === "otro_cáncer_maligno",
          },
        ];
        break;
      case "hematología":
        values = [
          {
            value: "anemias",
            label: "Anemias",
            selected: selectedNombreDeEnfermedad === "anemias",
          },
          {
            value: "púrpura",
            label: "Púrpura",
            selected: selectedNombreDeEnfermedad === "púrpura",
          },
          {
            value: "trastornos_de_coagulación",
            label: "Trastornos de coagulación",
            selected:
              selectedNombreDeEnfermedad === "trastornos_de_coagulación",
          },
          {
            value: "coagulación_secundaria_otros",
            label: "Coagulación Secundaria Otros",
            selected:
              selectedNombreDeEnfermedad === "coagulación_secundaria_otros",
          },
          {
            value: "sin_diagnóstico_confirmado",
            label: "Sin Diagnóstico Confirmado",
            selected:
              selectedNombreDeEnfermedad === "sin_diagnóstico_confirmado",
          },
        ];
        break;
      case "hematológicos_varios_(hv)":
        values = [
          {
            value: "hematológicos_varios_(hv)",
            label: "Hematológicos Varios (HV)",
            selected:
              selectedNombreDeEnfermedad === "hematológicos_varios_(hv)",
          },
          {
            value: "inmunología",
            label: "Inmunología",
            selected: selectedNombreDeEnfermedad === "inmunología",
          },
          {
            value: "sin_diagnóstico_confirmado_(hv)",
            label: "Sin Diagnóstico Confirmado (HV)",
            selected:
              selectedNombreDeEnfermedad === "sin_diagnóstico_confirmado_(hv)",
          },
          {
            value:
              "enfermedad_hematológica_no_especificada_de_otra_manera_(hv)",
            label:
              "Enfermedad Hematológica No Especificada de otra Manera (HV)",
            selected:
              selectedNombreDeEnfermedad ===
              "enfermedad_hematológica_no_especificada_de_otra_manera_(hv)",
          },
        ];
        break;
      case "leucemias":
        values = [
          {
            value: "leucemia_linfoblástica_aguda_(lla)",
            label: "Leucemia Linfoblástica Aguda (LLA)",
            selected:
              selectedNombreDeEnfermedad ===
              "leucemia_linfoblástica_aguda_(lla)",
          },
          {
            value: "leucemia_mieloide_aguda_(lma)",
            label: "Leucemia Mieloide Aguda (LMA)",
            selected:
              selectedNombreDeEnfermedad === "leucemia_mieloide_aguda_(lma)",
          },
          {
            value: "leucemia_mieloide_crónica_(lmc)",
            label: "Leucemia Mieloide Crónica (LMC)",
            selected:
              selectedNombreDeEnfermedad === "leucemia_mieloide_crónica_(lmc)",
          },
          {
            value: "leucemia_granulocítica_crónica_(lgc)",
            label: "Leucemia Granulocítica Crónica (LGC)",
            selected:
              selectedNombreDeEnfermedad ===
              "leucemia_granulocítica_crónica_(lgc)",
          },
          {
            value: "leucemia_otros",
            label: "Leucemia Otros",
            selected: selectedNombreDeEnfermedad === "leucemia_otros",
          },
          {
            value: "mieolodisplasia",
            label: "Mieolodisplasia",
            selected: selectedNombreDeEnfermedad === "mieolodisplasia",
          },
          {
            value: "síndrome_mielo_proliferativo",
            label: "Síndrome Mielo Proliferativo",
            selected:
              selectedNombreDeEnfermedad === "síndrome_mielo_proliferativo",
          },
          {
            value: "sin_diagnóstico_confirmado_leucemias",
            label: "Sin Diagnóstico Confirmado Leucemias",
            selected:
              selectedNombreDeEnfermedad ===
              "sin_diagnóstico_confirmado_leucemias",
          },
        ];
        break;
      case "linfomas_de_hodgkin":
        values = [
          {
            value: "linfoma_de_hodgkin_(lh)",
            label: "Linfoma de Hodgkin (LH)",
            selected: selectedNombreDeEnfermedad === "linfoma_de_hodgkin_(lh)",
          },
          {
            value: "sin_diagnóstico_confirmado_(lh)",
            label: "Sin Diagnóstico Confirmado (LH)",
            selected:
              selectedNombreDeEnfermedad === "sin_diagnóstico_confirmado_(lh)",
          },
        ];
        break;
      case "linfomas_no_hodgkin":
        values = [
          {
            value: "linfomas_de_no_hodgkin_(lnh)",
            label: "Linfoma de No Hodgkin (LNH)",
            selected:
              selectedNombreDeEnfermedad === "linfomas_de_no_hodgkin_(lnh)",
          },
          {
            value: "linfoma_linfoblástico_(lnh)",
            label: "Linfoma Linfoblástico (LNH)",
            selected:
              selectedNombreDeEnfermedad === "linfoma_linfoblástico_(lnh)",
          },
          {
            value: "sin_diagnóstico_confirmado_(lnh)",
            label: "Sin Diagnóstico Confirmado (LNH)",
            selected:
              selectedNombreDeEnfermedad === "sin_diagnóstico_confirmado_(lnh)",
          },
        ];
        break;
      case "tumor_de_retinoblastoma":
        values = [
          {
            value: "retinoblastoma",
            label: "Retinoblastoma",
            selected: selectedNombreDeEnfermedad === "retinoblastoma",
          },
          {
            value: "sin_diagnóstico_confirmado_retinoblastoma",
            label: "Sin Diagnóstico Confirmado Retinoblastoma",
            selected:
              selectedNombreDeEnfermedad ===
              "sin_diagnóstico_confirmado_retinoblastoma",
          },
        ];
        break;
      case "tumores_abdominales":
        values = [
          {
            value: "hepatoblastoma",
            label: "Hepatoblastoma",
            selected: selectedNombreDeEnfermedad === "hepatoblastoma",
          },
          {
            value: "hepatocelular",
            label: "Hepatocelular",
            selected: selectedNombreDeEnfermedad === "hepatocelular",
          },
          {
            value: "neuroblastoma",
            label: "Neuroblastoma",
            selected: selectedNombreDeEnfermedad === "neuroblastoma",
          },
          {
            value: "ganglioneuroblastoma_-_neuroblastoma",
            label: "Ganglioneuroblastoma - Neuroblastoma",
            selected:
              selectedNombreDeEnfermedad ===
              "ganglioneuroblastoma_-_neuroblastoma",
          },
          {
            value: "tumor_de_wilms",
            label: "Tumor de Wilms",
            selected: selectedNombreDeEnfermedad === "tumor_de_wilms",
          },
          {
            value: "cáncer_renal_no_de_wilms",
            label: "Cáncer Renal no de  Wilms",
            selected: selectedNombreDeEnfermedad === "cáncer_renal_no_de_wilms",
          },
          {
            value: "tumor_de_células_germinales",
            label: "Tumor de Células Germinales",
            selected:
              selectedNombreDeEnfermedad === "tumor_de_células_germinales",
          },
          {
            value: "sin_diagnóstico_confirmado_tumores_abdominales",
            label: "Sin Diagnóstico Confirmado Tumores Abdominales",
            selected:
              selectedNombreDeEnfermedad ===
              "sin_diagnóstico_confirmado_tumores_abdominales",
          },
          {
            value: "otros_tumores_abdominales_o_mediastinal",
            label: "Otros Tumores Abdominales o Mediastinal",
            selected:
              selectedNombreDeEnfermedad ===
              "otros_tumores_abdominales_o_mediastinal",
          },
        ];
        break;
      case "tumores_de_hueso":
        values = [
          {
            value: "sarcoma_de_ewing",
            label: "Sarcoma de Ewing",
            selected: selectedNombreDeEnfermedad === "sarcoma_de_ewing",
          },
          {
            value: "otro_sarcoma_de_ewing",
            label: "Otro Sarcoma de Ewing",
            selected: selectedNombreDeEnfermedad === "otro_sarcoma_de_ewing",
          },
          {
            value: "osteosarcoma",
            label: "Osteosarcoma",
            selected: selectedNombreDeEnfermedad === "osteosarcoma",
          },
          {
            value: "tumores_óseos_benignos_y_encondromas",
            label: "Tumores Óseos Benignos y Encondromas",
            selected:
              selectedNombreDeEnfermedad ===
              "tumores_óseos_benignos_y_encondromas",
          },
          {
            value: "tumores_óseos_benignos_otros",
            label: "Tumores Óseos Benignos Otros",
            selected:
              selectedNombreDeEnfermedad === "tumores_óseos_benignos_otros",
          },
          {
            value: "tumores_óseos_malignos_otros",
            label: "Tumores Óseos Malignos Otros",
            selected:
              selectedNombreDeEnfermedad === "tumores_óseos_malignos_otros",
          },
          {
            value: "sin_diagnóstico_confirmado_tumores_de_hueso",
            label: "Sin Diagnóstico Confirmado Tumores de Hueso",
            selected:
              selectedNombreDeEnfermedad ===
              "sin_diagnóstico_confirmado_tumores_de_hueso",
          },
        ];
        break;
      case "tumores_del_sistema_nervioso_central":
        values = [
          {
            value: "tumores_de_nervio_craneales_y_paraespinales",
            label: "Tumores de Nervio Craneales y Paraespinales",
            selected:
              selectedNombreDeEnfermedad ===
              "tumores_de_nervio_craneales_y_paraespinales",
          },
          {
            value: "tumor_maligno_de_la_vaina_del_nervio_periférico",
            label: "Tumor Maligno de la Vaina del Nervio Periférico",
            selected:
              selectedNombreDeEnfermedad ===
              "tumor_maligno_de_la_vaina_del_nervio_periférico",
          },
          {
            value: "schwannoma_(neurilemona_neurinoma)",
            label: "Schwannoma (Neurilemona, Neurinoma)",
            selected:
              selectedNombreDeEnfermedad ===
              "schwannoma_(neurilemona_neurinoma)",
          },
          {
            value: "tumores_de_tejido_neuroepitelial",
            label: "Tumores de Tejido Neuroepitelial",
            selected:
              selectedNombreDeEnfermedad === "tumores_de_tejido_neuroepitelial",
          },
          {
            value: "tumores_astrocíticos",
            label: "Tumores Astrocíticos",
            selected: selectedNombreDeEnfermedad === "tumores_astrocíticos",
          },
          {
            value: "astrocitoma_pilocítico",
            label: "Astrocitoma Pilocítico",
            selected: selectedNombreDeEnfermedad === "astrocitoma_pilocítico",
          },
          {
            value: "astrocitoma_difuso",
            label: "Astrocitoma Difuso",
            selected: selectedNombreDeEnfermedad === "astrocitoma_difuso",
          },
          {
            value: "glioblastoma",
            label: "Glioblastoma",
            selected: selectedNombreDeEnfermedad === "glioblastoma",
          },
          {
            value: "tumores_oligodendrogliales",
            label: "Tumores Oligodendrogliales",
            selected:
              selectedNombreDeEnfermedad === "tumores_oligodendrogliales",
          },
          {
            value: "tumores_ependimarios",
            label: "Tumores Ependimarios",
            selected: selectedNombreDeEnfermedad === "tumores_ependimarios",
          },
          {
            value: "ependimoma",
            label: "Ependimoma",
            selected: selectedNombreDeEnfermedad === "ependimoma",
          },
          {
            value: "tumores_del_plexo_coroideo",
            label: "Tumores del Plexo Coroideo",
            selected:
              selectedNombreDeEnfermedad === "tumores_del_plexo_coroideo",
          },
          {
            value: "tumores_neuronales_y_mixtos_neuronales_-_gliales",
            label: "Tumores Neuronales y mixtos Neuronales-Gliales",
            selected:
              selectedNombreDeEnfermedad ===
              "tumores_neuronales_y_mixtos_neuronales_-_gliales",
          },
          {
            value: "neurocitoma_central",
            label: "Neurocitoma Central",
            selected: selectedNombreDeEnfermedad === "neurocitoma_central",
          },
          {
            value: "tumores_de_la_región_pineal",
            label: "Tumores de la Región Pineal",
            selected:
              selectedNombreDeEnfermedad === "tumores_de_la_región_pineal",
          },
          {
            value: "tumores_embrionarios",
            label: "Tumores Embrionarios",
            selected: selectedNombreDeEnfermedad === "tumores_embrionarios",
          },
          {
            value: "meduloblastoma",
            label: "Meduloblastoma",
            selected: selectedNombreDeEnfermedad === "meduloblastoma",
          },
          {
            value: "tumores_neuroectodérmico_primitivo",
            label: "Tumores Neuroectodérmico Primitivo",
            selected:
              selectedNombreDeEnfermedad ===
              "tumores_neuroectodérmico_primitivo",
          },
          {
            value: "tumores_de_las_meninges",
            label: "Tumores de las Meninges",
            selected: selectedNombreDeEnfermedad === "tumores_de_las_meninges",
          },
          {
            value: "tumores_de_células_meningoteliales",
            label: "Tumores de Células Meningoteliales",
            selected:
              selectedNombreDeEnfermedad ===
              "tumores_de_células_meningoteliales",
          },
          {
            value: "meningioma_benigno",
            label: "Meningioma Benigno",
            selected: selectedNombreDeEnfermedad === "meningioma_benigno",
          },
          {
            value: "otras_neoplasias_relacionadas_con_las_meninges",
            label: "Otras Neoplasias Relacionadas con las Meninges",
            selected:
              selectedNombreDeEnfermedad ===
              "otras_neoplasias_relacionadas_con_las_meninges",
          },
          {
            value: "linfomas_y_neoplasias_hematopoyéticas",
            label: "Linfomas y Neoplasias Hematopoyéticas",
            selected:
              selectedNombreDeEnfermedad ===
              "linfomas_y_neoplasias_hematopoyéticas",
          },
          {
            value: "tumores_de_células_germinales",
            label: "Tumores de Células Germinales",
            selected:
              selectedNombreDeEnfermedad === "tumores_de_células_germinales",
          },
          {
            value: "teratoma",
            label: "Teratoma",
            selected: selectedNombreDeEnfermedad === "teratoma",
          },
          {
            value: "tumores_de_la_región_selar",
            label: "Tumores de la Región Selar",
            selected:
              selectedNombreDeEnfermedad === "tumores_de_la_región_selar",
          },
          {
            value: "astroblastoma",
            label: "Astroblastoma",
            selected: selectedNombreDeEnfermedad === "astroblastoma",
          },
          {
            value: "glioma_cordoideo_del_tercer_ventrículo",
            label: "Glioma Cordoideo del Tercer Ventrículo",
            selected:
              selectedNombreDeEnfermedad ===
              "glioma_cordoideo_del_tercer_ventrículo",
          },
          {
            value: "glioma_angiocéntrico",
            label: "Glioma Angiocéntrico",
            selected: selectedNombreDeEnfermedad === "glioma_angiocéntrico",
          },
          {
            value: "neurofibroma_plexiforme",
            label: "Neurofibroma Plexiforme",
            selected: selectedNombreDeEnfermedad === "neurofibroma_plexiforme",
          },
          {
            value: "liposarcoma",
            label: "Liposarcoma ",
            selected: selectedNombreDeEnfermedad === "liposarcoma",
          },
          {
            value: "condroma",
            label: "Condroma",
            selected: selectedNombreDeEnfermedad === "condroma",
          },
          {
            value: "hemangioendotelioma_epitelioide",
            label: "Hemangioendotelioma Epitelioide",
            selected:
              selectedNombreDeEnfermedad === "hemangioendotelioma_epitelioide",
          },
          {
            value: "angiosarcoma",
            label: "Angiosarcoma",
            selected: selectedNombreDeEnfermedad === "angiosarcoma",
          },
          {
            value: "sarcoma_de_kaposi",
            label: "Sarcoma de Kaposi",
            selected: selectedNombreDeEnfermedad === "sarcoma_de_kaposi",
          },
          {
            value: "melanomatosis_meningea",
            label: "Melanomatosis Meningea",
            selected: selectedNombreDeEnfermedad === "melanomatosis_meningea",
          },
          {
            value: "cordoma",
            label: "Cordoma",
            selected: selectedNombreDeEnfermedad === "cordoma",
          },
          {
            value: "sin_diagnóstico_confirmado_sistema_nervioso_central",
            label: "Sin Diagnóstico Confirmado Sistema Nervioso Central",
            selected:
              selectedNombreDeEnfermedad ===
              "sin_diagnóstico_confirmado_sistema_nervioso_central",
          },
        ];
        break;
      case "tumores_de_partes_blandas":
        values = [
          {
            value: "rabdomiosarcoma",
            label: "Rabdomiosarcoma",
            selected: selectedNombreDeEnfermedad === "rabdomiosarcoma",
          },
          {
            value: "sarcoma_no_rabdomiosarcoma",
            label: "Sarcoma No Rabdomiosarcoma",
            selected:
              selectedNombreDeEnfermedad === "sarcoma_no_rabdomiosarcoma",
          },
          {
            value: "otro_sarcoma_no_rabdomiosarcoma",
            label: "Otro Sarcoma No Rabdomiosarcoma",
            selected:
              selectedNombreDeEnfermedad === "otro_sarcoma_no_rabdomiosarcoma",
          },
          {
            value: "sin_diagnóstico_confirmado_tumores_de_partes_blandas",
            label: "Sin Diagnóstico Confirmado Tumores de Partes Blandas",
            selected:
              selectedNombreDeEnfermedad ===
              "sin_diagnóstico_confirmado_tumores_de_partes_blandas",
          },
        ];
        break;
      case "tumores_histiocítico":
        values = [
          {
            value: "tumor_histiocítico",
            label: "Tumor Histiocítico",
            selected: selectedNombreDeEnfermedad === "tumor_histiocítico",
          },
          {
            value: "sin_diagnóstico_confirmado_tumores_histiocítico",
            label: "Sin Diagnóstico Confirmado Tumores Histiocítico",
            selected:
              selectedNombreDeEnfermedad ===
              "sin_diagnóstico_confirmado_tumores_histiocítico",
          },
        ];
        break;
      case "tumores_varios_o_raros":
        values = [
          {
            value: "cáncer_de_piel",
            label: "Cáncer de Piel",
            selected: selectedNombreDeEnfermedad === "cáncer_de_piel",
          },
          {
            value: "cáncer_de_origen_primario_desconocido",
            label: "Cáncer de Origen Primario Desconocido",
            selected:
              selectedNombreDeEnfermedad ===
              "cáncer_de_origen_primario_desconocido",
          },
          {
            value: "mesotelioma",
            label: "Mesotelioma",
            selected: selectedNombreDeEnfermedad === "mesotelioma",
          },
          {
            value: "pancreatoblastoma",
            label: "Pancreatoblastoma",
            selected: selectedNombreDeEnfermedad === "pancreatoblastoma",
          },
          {
            value: "blastoma_pleuropulmonar",
            label: "Blastoma Pleuropulmonar",
            selected: selectedNombreDeEnfermedad === "blastoma_pleuropulmonar",
          },
          {
            value: "otros_tumores_varios_o_raros",
            label: "Otros Tumores Varios o Raros",
            selected:
              selectedNombreDeEnfermedad === "otros_tumores_varios_o_raros",
          },
          {
            value: "sin_diagnóstico_confirmado_tumores_raros",
            label: "Sin Diagnóstico Confirmado Tumores Raros",
            selected:
              selectedNombreDeEnfermedad ===
              "sin_diagnóstico_confirmado_tumores_raros",
          },
        ];
        break;
      case "tumores_sólidos":
        values = [
          {
            value: "carcinoma_de_parótida",
            label: "Carcinoma de Parótida",
            selected: selectedNombreDeEnfermedad === "carcinoma_de_parótida",
          },
          {
            value: "carcinoma_de_tiroides",
            label: "Carcinoma de Tiroides",
            selected: selectedNombreDeEnfermedad === "carcinoma_de_tiroides",
          },
          {
            value: "carcinoma_otros",
            label: "Carcinoma Otros",
            selected: selectedNombreDeEnfermedad === "carcinoma_otros",
          },
          {
            value: "tumores_de_glándulas_salivales_carcinoma",
            label: "Tumores de Glándulas Salivales Carcinoma",
            selected:
              selectedNombreDeEnfermedad ===
              "tumores_de_glándulas_salivales_carcinoma",
          },
          {
            value: "carcinomas_otros_no_especificado",
            label: "Carcinomas Otros No Especificado",
            selected:
              selectedNombreDeEnfermedad === "carcinomas_otros_no_especificado",
          },
          {
            value: "sin_diagnostico_confirmado_tumores_solidos",
            label: "Sin Diagnóstico Confirmado Tumores Solidos",
            selected:
              selectedNombreDeEnfermedad ===
              "sin_diagnostico_confirmado_tumores_solidos",
          },
        ];
        break;
      case "sin_diagnóstico":
        values = [
          {
            value: "sin_diagnóstico",
            label: "Sin Diagnóstico",
            selected: selectedNombreDeEnfermedad === "sin_diagnóstico",
          },
        ];
        break;
      // fin
      default:
        values = [
          {
            value: "seleccionar_diagnostico",
            label: "Seleccionar Diagnóstico",
            selected: selectedNombreDeEnfermedad === "seleccionar_diagnostico",
          },
        ];
    }
    return values;
  }

  histologiaOptions(nombreDeEnfermedad, selectedHistologia) {
    let values;

    switch (nombreDeEnfermedad) {
      case "masas_o_tumores_benignos":
        values = [
          {
            value: "linfadenopatía_benigna",
            label: "Linfadenopatía Benigna",
            selected: selectedHistologia === "linfadenopatía_benigna",
          },
          {
            value: "nefroma_mesoblastico_indeterminado",
            label: "Nefroma Mesoblastico Indeterminado",
            selected:
              selectedHistologia === "nefroma_mesoblastico_indeterminado",
          },
          {
            value: "sarcoma_de_células_claras_benigna",
            label: "Sarcoma de células Claras Benigna",
            selected:
              selectedHistologia === "sarcoma_de_células_claras_benigna",
          },
          {
            value: "inclasificable_(otra_enfermedad)_benigna",
            label: "Inclasificable (Otra enfermedad) Benigna",
            selected:
              selectedHistologia === "inclasificable_(otra_enfermedad)_benigna",
          },
          {
            value: "fibroma_condromixoide_benigno",
            label: "Fibroma Condromixoide Benigno",
            selected: selectedHistologia === "fibroma_condromixoide_benigno",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "hemangioma_benigno":
        values = [
          {
            value: "hemangioma_capilar_benigno",
            label: "Hemangioma Capilar Benigno",
            selected: selectedHistologia === "hemangioma_capilar_benigno",
          },
          {
            value: "hemangioma_cavernoso_benigno",
            label: "Hemangioma Cavernoso Benigno",
            selected: selectedHistologia === "hemangioma_cavernoso_benigno",
          },
          {
            value: "hemangioma_plano_-_oporto_benigno",
            label: "Hemangioma Plano - Oporto Benigno",
            selected:
              selectedHistologia === "hemangioma_plano_-_oporto_benigno",
          },
          {
            value: "hemangioma_mixto_benigno",
            label: "Hemangioma Mixto Benigno",
            selected: selectedHistologia === "hemangioma_mixto_benigno",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "sin_diagnóstico_confirmado":
        values = [
          {
            value: "sin_diagnóstico_confirmado",
            label: "Sin Diagnóstico Confirmado",
            selected: selectedHistologia === "sin_diagnóstico_confirmado",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "anemias":
        values = [
          {
            value: "anemia_con_deficiencia_de_hierro",
            label: "Anemia con Deficiencia de Hierro",
            selected: selectedHistologia === "anemia_con_deficiencia_de_hierro",
          },
          {
            value: "anemia_aplásica",
            label: "Anemia Aplásica",
            selected: selectedHistologia === "anemia_aplásica",
          },
          {
            value: "anemia_de_fanconi",
            label: "Anemia de Fanconi",
            selected: selectedHistologia === "anemia_de_fanconi",
          },
          {
            value: "anemia_hemolítica",
            label: "Anemia Hemolítica",
            selected: selectedHistologia === "anemia_hemolítica",
          },
          {
            value: "anemia_megaloblástica",
            label: "Anemia Megaloblástica",
            selected: selectedHistologia === "anemia_megaloblástica",
          },
          {
            value: "anemia_ferropénica",
            label: "Anemia Ferropénica",
            selected: selectedHistologia === "anemia_ferropénica",
          },
          {
            value: "anemia_microesferocitica",
            label: "Anemia Microesferocitica",
            selected: selectedHistologia === "anemia_microesferocitica",
          },
          {
            value: "anemia_megaloblastica",
            label: "Anemia Megaloblastica",
            selected: selectedHistologia === "anemia_megaloblastica",
          },
          {
            value: "anemia_drepanocitica",
            label: "Anemia Drepanocitica",
            selected: selectedHistologia === "anemia_drepanocitica",
          },
          {
            value: "anemia_otras_no_especificada_de_otra_manera",
            label: "Anemia Otras no Especificada de otra Manera",
            selected:
              selectedHistologia ===
              "anemia_otras_no_especificada_de_otra_manera",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "púrpura":
        values = [
          {
            value: "púrpura_trombocitopénica_idiopática_(PTI)",
            label: "Púrpura Trombocitopénica Idiopática (PTI)",
            selected:
              selectedHistologia ===
              "púrpura_trombocitopénica_idiopática_(PTI)",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "trastornos_de_coagulación":
        values = [
          {
            value: "hemofilia_a",
            label: "Hemofilia A",
            selected: selectedHistologia === "hemofilia_a",
          },
          {
            value: "hemofilia_b",
            label: "Hemofilia B",
            selected: selectedHistologia === "hemofilia_b",
          },
          {
            value: "enfermedad_de_von_willebrand",
            label: "Enfermedad de Von Willebrand",
            selected: selectedHistologia === "enfermedad_de_von_willebrand",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "sin_diagnóstico_confirmado":
        values = [
          {
            value: "sin_diagnóstico_confirmado",
            label: "Sin Diagnóstico Confirmado",
            selected: selectedHistologia === "sin_diagnóstico_confirmado",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "hematológicos_varios_(hv)":
        values = [
          {
            value: "aplasia_eritrocitica_pura_(hv)",
            label: "Aplasia Eritrocitica Pura (HV)",
            selected: selectedHistologia === "aplasia_eritrocitica_pura_(hv)",
          },
          {
            value: "leucopenia_(hv)",
            label: "Leucopenia (HV)",
            selected: selectedHistologia === "leucopenia_(hv)",
          },
          {
            value: "talasemia_(hv)",
            label: "Talasemia (HV)",
            selected: selectedHistologia === "talasemia_(hv)",
          },
          {
            value: "trombocitopenia_(otras)_(hv)",
            label: "Trombocitopenia (Otras) (HV)",
            selected: selectedHistologia === "trombocitopenia_(otras)_(hv)",
          },
          {
            value: "trombocitosis_(hv)",
            label: "Trombocitosis (HV)",
            selected: selectedHistologia === "trombocitosis_(hv)",
          },
          {
            value: "trastorno_hemorrágico_no_especificado_(hv)",
            label: "Trastorno Hemorrágico No Especificado (HV)",
            selected:
              selectedHistologia ===
              "trastorno_hemorrágico_no_especificado_(hv)",
          },
          {
            value: "deficiencia_de_G6PD_beninga_(hv)",
            label: "Deficiencia de G6PD Beninga (HV)",
            selected: selectedHistologia === "deficiencia_de_G6PD_beninga_(hv)",
          },
          {
            value: "trastorno_de_la_función_plaquetaria_(hv)",
            label: "Trastorno de la Función Plaquetaria (HV)",
            selected:
              selectedHistologia === "trastorno_de_la_función_plaquetaria_(hv)",
          },
          {
            value: "sindrome_hemafagocítico_(hv)",
            label: "Sindrome Hemafagocítico (HV)",
            selected: selectedHistologia === "sindrome_hemafagocítico_(hv)",
          },
          {
            value: "sindrome_mielodisplásicos_(hv)",
            label: "Sindrome Mielodisplásicos (HV)",
            selected: selectedHistologia === "sindrome_mielodisplásicos_(hv)",
          },
          {
            value: "sindrome_mieloproliferativos_(hv)",
            label: "Sindrome Mieloproliferativos (HV)",
            selected:
              selectedHistologia === "sindrome_mieloproliferativos_(hv)",
          },
          {
            value: "hiper_gammaglobulinemia_(hv)",
            label: "Hiper Gammaglobulinemia (HV)",
            selected: selectedHistologia === "hiper_gammaglobulinemia_(hv)",
          },
          {
            value: "neutropenia_no_especificada_(hv)",
            label: "Neutropenia no especificada (HV)",
            selected: selectedHistologia === "neutropenia_no_especificada_(hv)",
          },
          {
            value: "pancitopenia_(hv)",
            label: "Pancitopenia (HV)",
            selected: selectedHistologia === "pancitopenia_(hv)",
          },
          {
            value: "hemoglobinuria_paroxística_nocturna_(hv)",
            label: "Hemoglobinuria Paroxística Nocturna (HV)",
            selected:
              selectedHistologia === "hemoglobinuria_paroxística_nocturna_(hv)",
          },
          {
            value: "esplenomegalia_(hv)",
            label: "Esplenomegalia (HV)",
            selected: selectedHistologia === "esplenomegalia_(hv)",
          },
          {
            value: "hepatomegalia_(hv)",
            label: "Hepatomegalia (HV)",
            selected: selectedHistologia === "hepatomegalia_(hv)",
          },
          {
            value: "coagulopatías_secundarias_(hv)",
            label: "Coagulopatías Secundarias (HV)",
            selected: selectedHistologia === "coagulopatías_secundarias_(hv)",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "inmunología":
        values = [
          {
            value: "enfermedad_autoinmune_no_especificada_de_otra_manera_(hv)",
            label:
              "Enfermedad Autoinmune, no especificada de otra manera - (HV)",
            selected:
              selectedHistologia ===
              "enfermedad_autoinmune_no_especificada_de_otra_manera_(hv)",
          },
          {
            value:
              "inmunodeficiencia_primaria_no_especificada_de_otra_manera_(hv)",
            label:
              "Inmunodeficiencia Primaria, no especificada de otra manera - (HV)",
            selected:
              selectedHistologia ===
              "inmunodeficiencia_primaria_no_especificada_de_otra_manera_(hv)",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "sin_diagnóstico_confirmado_(hv)":
        values = [
          {
            value: "sin_diagnóstico_confirmado_(hv)",
            label: "Sin Diagnóstico Confirmado (hv)",
            selected: selectedHistologia === "sin_diagnóstico_confirmado_(hv)",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "leucemia_linfoblástica_aguda_(lla)":
        values = [
          {
            value: "lla_estirpe_b",
            label: "LLA Estirpe B",
            selected: selectedHistologia === "lla_estirpe_b",
          },
          {
            value: "lla_estirpe_t",
            label: "LLA Estirpe T",
            selected: selectedHistologia === "lla_estirpe_t",
          },
          {
            value: "leucemia_aguda_de_bilinaje_(lla_y_lma)",
            label: "Leucemia Aguda de Bilinaje (LLA y LMA)",
            selected:
              selectedHistologia === "leucemia_aguda_de_bilinaje_(lla_y_lma)",
          },
          {
            value: "indiferenciado_(lla)",
            label: "Indiferenciado (LLA)",
            selected: selectedHistologia === "indiferenciado_(lla)",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "leucemia_mieloide_aguda_(lma)":
        values = [
          {
            value: "leucemia_mieloide_aguda_(lma)_-_m0",
            label: "Leucemia Mieloide aguda (LMA) - M0",
            selected:
              selectedHistologia === "leucemia_mieloide_aguda_(lma)_-_m0",
          },
          {
            value: "leucemia_mieloide_aguda_(lma)_-_m1",
            label: "Leucemia Mieloide aguda (LMA) - M1",
            selected:
              selectedHistologia === "leucemia_mieloide_aguda_(lma)_-_m1",
          },
          {
            value: "leucemia_mieloide_aguda_(lma)_-_m2",
            label: "Leucemia Mieloide aguda (LMA) - M2",
            selected:
              selectedHistologia === "leucemia_mieloide_aguda_(lma)_-_m2",
          },
          {
            value: "leucemia_mieloide_aguda_(lma)_-_m4",
            label: "Leucemia Mieloide aguda (LMA) - M4",
            selected:
              selectedHistologia === "leucemia_mieloide_aguda_(lma)_-_m4",
          },
          {
            value: "leucemia_mieloide_aguda_(lma)_-_m5",
            label: "Leucemia Mieloide aguda (LMA) - M5",
            selected:
              selectedHistologia === "leucemia_mieloide_aguda_(lma)_-_m5",
          },
          {
            value: "leucemia_mieloide_aguda_(lma)_-_m6",
            label: "Leucemia Mieloide aguda (LMA) - M6",
            selected:
              selectedHistologia === "leucemia_mieloide_aguda_(lma)_-_m6",
          },
          {
            value: "leucemia_mieloide_aguda_(lma)_-_m7",
            label: "Leucemia Mieloide aguda (LMA) - M7",
            selected:
              selectedHistologia === "leucemia_mieloide_aguda_(lma)_-_m7",
          },
          {
            value: "leucemia_promielocitica_aguda_(lpa)_-_m3",
            label: "Leucemia Promielocitica Aguda (LPA) - M3",
            selected:
              selectedHistologia === "leucemia_promielocitica_aguda_(lpa)_-_m3",
          },
          {
            value: "leucemia_mieloide_aguda_sin_linaje_(LMA)",
            label: "Leucemia Mieloide Aguda - Sin Linaje (LMA)",
            selected:
              selectedHistologia ===
              "leucemia_mieloide_aguda_sin_linaje_(lpa)_-_m3",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "leucemia_mieloide_crónica_(lmc)":
        values = [
          {
            value: "leucemia_mieloide_crónica_(lmc)",
            label: "Leucemia Mieloide Crónica (LMC)",
            selected: selectedHistologia === "leucemia_mieloide_crónica_(lmc)",
          },
          {
            value: "leucemia_mieloide_crónica_juvenil_(lmc)",
            label: "Leucemia Mieloide Crónica Juvenil (LMC-Juvenil)",
            selected:
              selectedHistologia === "leucemia_mieloide_crónica_juvenil_(lmc)",
          },
          {
            value: "leucemia_mieolomonocitica_crónica_(lmmc)",
            label: "Leucemia Mieolomonocitica Crónica (LMMC)",
            selected:
              selectedHistologia === "leucemia_mieolomonocitica_crónica_(lmmc)",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "leucemia_granulocítica_crónica_(lgc)":
        values = [
          {
            value: "leucemia_granulocítica_crónica_(lgc)",
            label: "Leucemia Granulocítica Crónica (LGC)",
            selected:
              selectedHistologia === "leucemia_granulocítica_crónica_(lgc)",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "leucemia_otros":
        values = [
          {
            value: "leucemia_mielomonocitica_juvenil_(lmmj)",
            label: "Leucemia Mielomonocitica Juvenil (LMMJ)",
            selected:
              selectedHistologia === "leucemia_mielomonocitica_juvenil_(lmmj)",
          },
          {
            value: "leucemia_aguda_no_diferenciada_-_1e",
            label: "Leucemia Aguda no diferenciada - 1e",
            selected:
              selectedHistologia === "leucemia_aguda_no_diferenciada_-_1e",
          },
          {
            value: "leucemia_aguda_inclasificable_-_1e",
            label: "Leucemia Aguda Inclasificable - 1e",
            selected:
              selectedHistologia === "leucemia_aguda_inclasificable_-_1e",
          },
          {
            value: "leucemia_no_especificada",
            label: "Leucemia No Especificada",
            selected: selectedHistologia === "leucemia_no_especificada",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "mieolodisplasia":
        values = [
          {
            value: "mieolodisplasia",
            label: "Mieolodisplasia",
            selected: selectedHistologia === "mieolodisplasia",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "sin_diagnóstico_confirmado_leucemias":
        values = [
          {
            value: "sin_diagnóstico_confirmado_leucemias",
            label: "Sin Diagnóstico Confirmado Leucemias",
            selected:
              selectedHistologia === "sin_diagnóstico_confirmado_leucemias",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "linfoma_de_hodgkin_(lh)":
        values = [
          {
            value: "predominio_de_linfocitos_(lh)",
            label: "Predominio de Linfocitos (LH)",
            selected: selectedHistologia === "predominio_de_linfocitos_(lh)",
          },
          {
            value: "esclerosis_nodular_(lh)",
            label: "Esclerosis Nodular (LH)",
            selected: selectedHistologia === "esclerosis_nodular_(lh)",
          },
          {
            value: "celularidad_mixta_(lh)",
            label: "Celularidad Mixta (LH)",
            selected: selectedHistologia === "celularidad_mixta_(lh)",
          },
          {
            value: "depleción_de_linfocitos_(lh)",
            label: "Depleción de linfocitos (LH)",
            selected: selectedHistologia === "depleción_de_linfocitos_(lh)",
          },
          {
            value: "no_especificado_(lh)",
            label: "No Especificado (LH)",
            selected: selectedHistologia === "no_especificado_(lh)",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "sin_diagnóstico_confirmado_(lh)":
        values = [
          {
            value: "sin_diagnóstico_confirmado_(lh)",
            label: "Sin Datos Confirmado (LH)",
            selected: selectedHistologia === "sin_diagnóstico_confirmado_(lh)",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "linfomas_de_no_hodgkin_(lnh)":
        values = [
          {
            value: "no_especificado_(lnh)",
            label: "no especificado (LNH)",
            selected: selectedHistologia === "no_especificado_(lnh)",
          },
          {
            value: "linfoma_de_burkitt_(lnh)",
            label: "Linfoma de Burkitt (LNH)",
            selected: selectedHistologia === "linfoma_de_burkitt_(lnh)",
          },
          {
            value: "linfoma_de_células_grandes_de_células_b_(lnh)",
            label: "Linfoma de Células Grandes de Células B (LNH)",
            selected:
              selectedHistologia ===
              "linfoma_de_células_grandes_de_células_b_(lnh)",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "sin_diagnóstico_confirmado_(lh)":
        values = [
          {
            value: "sin_diagnóstico_confirmado_(lh)",
            label: "Sin Datos Confirmado (LH)",
            selected: selectedHistologia === "sin_diagnóstico_confirmado_(lh)",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "linfoma_linfoblástico_(lnh)":
        values = [
          {
            value: "linfoma_linfoblásico_de_linaje_b_(lnh)",
            label: "Linfoma Linfoblásico de Linaje B (LNH)",
            selected:
              selectedHistologia === "linfoma_linfoblásico_de_linaje_b_(lnh)",
          },
          {
            value: "linfoma_linfoblásico_de_linaje_t_(lnh)",
            label: "Linfoma Linfoblásico de Linaje T (LNH)",
            selected:
              selectedHistologia === "linfoma_linfoblásico_de_linaje_t_(lnh)",
          },
          {
            value: "linfoma_anaplásico_de_células_no_b_no_anaplásico_(lnh)",
            label: "Linfoma Anaplásico de Células no B no Anaplásico (LNH)",
            selected:
              selectedHistologia ===
              "linfoma_anaplásico_de_células_no_b_no_anaplásico_(lnh)",
          },
          {
            value: "linfoma_nasofaríngeo_de_células_nk/t_(lnh)",
            label: "Linfoma Nasofaríngeo de Células NK/T (LNH)",
            selected:
              selectedHistologia ===
              "linfoma_nasofaríngeo_de_células_nk/t_(lnh)",
          },
          {
            value: "linfoma_mediastinico_de_células_b_primario_(lnh)",
            label: "Linfoma Mediastinico de Células B Primario (LNH)",
            selected:
              selectedHistologia ===
              "linfoma_mediastinico_de_células_b_primario_(lnh)",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "sin_diagnóstico_confirmado_(lnh)":
        values = [
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "retinoblastoma":
        values = [
          {
            value: "retinoblastoma",
            label: "Retinoblastoma",
            selected: selectedHistologia === "retinoblastoma",
          },
          {
            value: "retinoblastoma_nos",
            label: "Retinoblastoma NOS",
            selected: selectedHistologia === "retinoblastoma_nos",
          },
          {
            value: "retinoblastoma_diferenciado",
            label: "Retinoblastoma Diferenciado",
            selected: selectedHistologia === "retinoblastoma_diferenciado",
          },
          {
            value: "retinoblastoma_indiferenciado",
            label: "Retinoblastoma Indiferenciado",
            selected: selectedHistologia === "retinoblastoma_indiferenciado",
          },
          {
            value: "retinoblastoma_difuso",
            label: "Retinoblastoma Difuso",
            selected: selectedHistologia === "retinoblastoma_difuso",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "sin_diagnóstico_confirmado_retinoblastoma":
        values = [
          {
            value: "sin_diagnóstico_confirmado_retinoblastoma",
            label: "Sin Diagnostico Confirmado Retinoblastoma",
            selected:
              selectedHistologia ===
              "sin_diagnóstico_confirmado_retinoblastoma",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "hepatoblastoma":
        values = [
          {
            value: "fetal",
            label: "Fetal",
            selected: selectedHistologia === "fetal",
          },
          {
            value: "indiferenciado",
            label: "Indiferenciado",
            selected: selectedHistologia === "indiferenciado",
          },
          {
            value: "embrionario",
            label: "Embrionario",
            selected: selectedHistologia === "embrionario",
          },
          {
            value: "epitelial",
            label: "Epitelial",
            selected: selectedHistologia === "epitelial",
          },
          {
            value: "macrotrabecular",
            label: "Macrotrabecular",
            selected: selectedHistologia === "macrotrabecular",
          },
          {
            value: "mixto",
            label: "Mixto",
            selected: selectedHistologia === "mixto",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "hepatocelular":
        values = [
          {
            value: "fibrolamelar",
            label: "Fibrolamelar",
            selected: selectedHistologia === "fibrolamelar",
          },
          {
            value: "no_fibrolamelar",
            label: "No Fibrolamelar",
            selected: selectedHistologia === "no_fibrolamelar",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "neuroblastoma":
        values = [
          {
            value: "ganglioneuroma",
            label: "Ganglioneuroma",
            selected: selectedHistologia === "ganglioneuroma",
          },
          {
            value: "ganglioneuroma_benigna",
            label: "Ganglioneuroma Benigna",
            selected: selectedHistologia === "ganglioneuroma_benigna",
          },
          {
            value: "neuroblastoma_histología_indeterminada",
            label: "Neuroblastoma Histología Indeterminada",
            selected:
              selectedHistologia === "neuroblastoma_histología_indeterminada",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "ganglioneuroblastoma_-_neuroblastoma":
        values = [
          {
            value: "ganglioneuroma_benigno",
            label: "Ganglioneuroblastoma Benigno",
            selected: selectedHistologia === "ganglioneuroma_benigno",
          },
          {
            value: "ganglioneuroma_maligno",
            label: "Ganglioneuroblastoma Maligno",
            selected: selectedHistologia === "ganglioneuroma_maligno",
          },
          {
            value: "ganglioneuroma",
            label: "Ganglioneuroblastoma",
            selected: selectedHistologia === "ganglioneuroma",
          },
          {
            value: "ganglioneuroma_nodular_neuroblastoma_benigno",
            label: "Ganglioneuroblastoma Nodular - Neuroblastoma Benigno",
            selected:
              selectedHistologia ===
              "ganglioneuroma_nodular_neuroblastoma_benigno",
          },
          {
            value: "feocromocitoma_-_tumores_raros_benigno",
            label: "Feocromocitoma - Tumores Raros Benigno",
            selected:
              selectedHistologia === "feocromocitoma_-_tumores_raros_benigno",
          },
          {
            value: "estesioneuroblastoma_-_tumores_raros_maligno",
            label: "Estesioneuroblastoma - Tumores Raros Maligno",
            selected:
              selectedHistologia ===
              "estesioneuroblastoma_-_tumores_raros_maligno",
          },
          {
            value: "estroma_de_schwannian_pobre_benigno_neuroblastoma",
            label: "Estroma de Schwannian Pobre Benigno Neuroblastoma",
            selected:
              selectedHistologia ===
              "estroma_de_schwannian_pobre_benigno_neuroblastoma",
          },
          {
            value: "neuroblástico_eriférico",
            label: "Neuroblástico Periférico",
            selected: selectedHistologia === "neuroblástico_eriférico",
          },
          {
            value: "neuroblastoma_histología_indeterminada",
            label: "Neuroblastoma Histologia Indeterminada",
            selected:
              selectedHistologia === "neuroblastoma_histología_indeterminada",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "tumor_de_wilms":
        values = [
          {
            value: "histología_favorable",
            label: "Histología Favorable",
            selected: selectedHistologia === "histología_favorable",
          },
          {
            value: "histología_favorable_con_alteraciones_mucleares",
            label: "Histología Favorable con Alteraciones Nucleares",
            selected:
              selectedHistologia ===
              "histología_favorable_con_alteraciones_mucleares",
          },
          {
            value: "histología_anaplásica",
            label: "Histología Anaplásica",
            selected: selectedHistologia === "histología_anaplásica",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
          {
            value: "histología_desfavorable",
            label: "Histología Desfavorable",
            selected: selectedHistologia === "histología_desfavorable",
          },
        ];
        break;
      case "cáncer_renal_no_de_wilms":
        values = [
          {
            value: "tumor_rabdoideo",
            label: "Tumor Rabdoideo",
            selected: selectedHistologia === "tumor_rabdoideo",
          },
          {
            value: "sarcoma_de_células_claras",
            label: "Sarcoma de Celulas Claras",
            selected: selectedHistologia === "sarcoma_de_células_claras",
          },
          {
            value: "carcinoma_de_células_claras",
            label: "Carcinoma de Celulas Claras",
            selected: selectedHistologia === "carcinoma_de_células_claras",
          },
          {
            value: "nefroma_mesoblástico_indeterminado",
            label: "Nefroma Mesoblástico Indeterminado",
            selected:
              selectedHistologia === "nefroma_mesoblástico_indeterminado",
          },
          {
            value: "tumor_renal_no_especificado",
            label: "Tumor Renal no Especificado",
            selected: selectedHistologia === "tumor_renal_no_especificado",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "tumor_de_células_germinales":
        values = [
          {
            value: "teratoma_maduro_gonadoblastoma_-_benigno",
            label: "Teratoma Maduro Gonadoblastoma - Benigno",
            selected:
              selectedHistologia === "teratoma_maduro_gonadoblastoma_-_benigno",
          },
          {
            value: "gonadoblastoma_-_indeterminado_benigno",
            label: "Gonadoblastoma - Indeterminado Benigno",
            selected:
              selectedHistologia === "gonadoblastoma_-_indeterminado_benigno",
          },
          {
            value: "comportamiento_indeterminado_teratoma_inmaduro",
            label: "Comportamiento Indeterminado Teratoma Inmaduro",
            selected:
              selectedHistologia ===
              "comportamiento_indeterminado_teratoma_inmaduro",
          },
          {
            value: "geminoma_seminoma_hombres_-_maligno",
            label: "Geminoma Seminoma Hombres - Maligno",
            selected:
              selectedHistologia === "geminoma_seminoma_hombres_-_maligno",
          },
          {
            value: "geminoma_disgerminoma_hembres_-_maligno",
            label: "Geminoma Disgerminoma Hembras - Maligno",
            selected:
              selectedHistologia === "geminoma_disgerminoma_hembres_-_maligno",
          },
          {
            value:
              "carcinoma_del_saco_vitelino_tumor_de_seno_endodérmico_-_maligno",
            label:
              "Carcinoma del Saco Vitelino Tumor de Seno Endodérmico - Maligno",
            selected:
              selectedHistologia ===
              "carcinoma_del_saco_vitelino_tumor_de_seno_endodérmico_-_maligno",
          },
          {
            value: "carcinoma_embrionario",
            label: "Carcinoma  Embrionario",
            selected: selectedHistologia === "carcinoma_embrionario",
          },
          {
            value: "coriocarcinoma",
            label: "Coriocarcinoma",
            selected: selectedHistologia === "coriocarcinoma",
          },
          {
            value:
              "tumor_maligno_mixto_de_células_germinales_teratoma_maduro_o_inmaduro",
            label:
              "Tumor Maligno Mixto de Células Germinales Teratoma Maduro o Inmaduro",
            selected:
              selectedHistologia ===
              "tumor_maligno_mixto_de_células_germinales_teratoma_maduro_o_inmaduro",
          },
          {
            value: "tumor_de_células_germinales_extragonadal",
            label: "Tumor de Células Germinales Extragonadal",
            selected:
              selectedHistologia === "tumor_de_células_germinales_extragonadal",
          },
          {
            value: "carcinoma_gonadal",
            label: "Carcinoma Gonadal",
            selected: selectedHistologia === "carcinoma_gonadal",
          },
          {
            value: "teratoma_maduro_benigna_germinales",
            label: "Teratoma Maduro Benigna Germinales",
            selected:
              selectedHistologia === "teratoma_maduro_benigna_germinales",
          },
          {
            value: "teratoma_benigno_germinales",
            label: "Teratoma Benigno Germinales",
            selected: selectedHistologia === "teratoma_benigno_germinales",
          },
          {
            value: "teratoma_inmaduro_benigno_germinales",
            label: "Teratoma Inmaduro Benigno Germinales",
            selected:
              selectedHistologia === "teratoma_inmaduro_benigno_germinales",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "sin_diagnóstico_confirmado_tumores_abdominales":
        values = [
          {
            value: "sin_diagnóstico_confirmado_tumores_abdominales",
            label: "Sin Diagnóstico Confirmado Tumores Abdominales",
            selected:
              selectedHistologia ===
              "sin_diagnóstico_confirmado_tumores_abdominales",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "sarcoma_de_ewing":
        values = [
          {
            value: "hueso",
            label: "Hueso",
            selected: selectedHistologia === "hueso",
          },
          {
            value: "partes_blandas",
            label: "Partes Blandas",
            selected: selectedHistologia === "partes_blandas",
          },
          {
            value: "tumor_de_askin_partes_blandas",
            label: "Tumor de Askin Partes Blandas",
            selected: selectedHistologia === "tumor_de_askin_partes_blandas",
          },
          {
            value: "partes_blandas_extraóseo_blando_-_alas_extraóselas",
            label: "Partes Blandas Extraóseo Blando - Alas Extraóselas",
            selected:
              selectedHistologia ===
              "partes_blandas_extraóseo_blando_-_alas_extraóselas",
          },
          {
            value: "partes_blandas_tumor_desmoplásico_de_células_pequeñas",
            label: "Partes Blandas Tumor Desmoplásico de Células Pequeñas",
            selected:
              selectedHistologia ===
              "partes_blandas_tumor_desmoplásico_de_células_pequeñas",
          },
          {
            value: "tumor_nuroectodérmico",
            label: "Tumor Nuroectodérmico",
            selected: selectedHistologia === "tumor_nuroectodérmico",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "osteosarcoma":
        values = [
          {
            value: "osteosarcoma_convencional",
            label: "Osteosarcoma Convencional",
            selected: selectedHistologia === "osteosarcoma_convencional",
          },
          {
            value: "osteosarcoma_telangiectásico",
            label: "Osteosarcoma Telangiectásico",
            selected: selectedHistologia === "osteosarcoma_telangiectásico",
          },
          {
            value: "osteosarcoma_células_pequeñas",
            label: "Osteosarcoma Células Pequeñas",
            selected: selectedHistologia === "osteosarcoma_células_pequeñas",
          },
          {
            value: "osteosarcoma_multifocal",
            label: "Osteosarcoma Multifocal",
            selected: selectedHistologia === "osteosarcoma_multifocal",
          },
          {
            value: "osteosarcoma_parosteal",
            label: "Osteosarcoma Parosteal",
            selected: selectedHistologia === "osteosarcoma_parosteal",
          },
          {
            value: "osteosarcoma_periosteal",
            label: "Osteosarcoma Periosteal",
            selected: selectedHistologia === "osteosarcoma_periosteal",
          },
          {
            value: "osteosarcoma_extraóseo",
            label: "Osteosarcoma Extraóseo",
            selected: selectedHistologia === "osteosarcoma_extraóseo",
          },
          {
            value: "osteosarcoma_no_especificado",
            label: "Osteosarcoma No Especificado",
            selected: selectedHistologia === "osteosarcoma_no_especificado",
          },
          {
            value: "osteosarcoma_condroblastico",
            label: "Osteosarcoma Condroblastico",
            selected: selectedHistologia === "osteosarcoma_condroblastico",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "tumores_óseos_benignos_y_encondromas":
        values = [
          {
            value: "osteoma_osteoide_benigno",
            label: "Osteoma Osteoide Benigno",
            selected: selectedHistologia === "osteoma_osteoide_benigno",
          },
          {
            value: "osteoblastoma_benigno",
            label: "Osteoblastoma Benigno",
            selected: selectedHistologia === "osteoblastoma_benigno",
          },
          {
            value: "osteocondroma_benigno",
            label: "Osteocondroma Benigno",
            selected: selectedHistologia === "osteocondroma_benigno",
          },
          {
            value: "encondroma_benigno",
            label: "Encondroma Benigno",
            selected: selectedHistologia === "encondroma_benigno",
          },
          {
            value: "encondroma_múltiple_benigno_(enfermedad_de_ollier)",
            label: "Encondroma Múltiple Benigno (enfermedad de Ollier)",
            selected:
              selectedHistologia ===
              "encondroma_múltiple_benigno_(enfermedad_de_ollier)",
          },
          {
            value: "osteoblastico_benigno",
            label: "Osteoblastico Benigno",
            selected: selectedHistologia === "osteoblastico_benigno",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;

      //
      case "sin_diagnóstico_confirmado_tumores_de_hueso":
        values = [
          {
            value: "sin_diagnóstico_confirmado_tumores_de_hueso",
            label: "Sin Diagnóstico Confirmado Tumores de Hueso",
            selected:
              selectedHistologia ===
              "sin_diagnóstico_confirmado_tumores_de_hueso",
          },
        ];
        break;
      case "tumores_de_nervio_craneales_y_paraespinales":
        values = [
          {
            value: "tumores_de_nervio_craneales_y_paraespinales",
            label: "Tumores de Nervios Craneales y Paraespinales",
            selected:
              selectedHistologia ===
              "tumores_de_nervio_craneales_y_paraespinales",
          },
          {
            value: "perineurioma",
            label: "Perineurioma",
            selected: selectedHistologia === "perineurioma",
          },
          {
            value: "perineurioma_maligno",
            label: "Perineurioma Maligno",
            selected: selectedHistologia === "perineurioma_maligno",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "tumor_maligno_de_la_vaina_del_nervio_periférico":
        values = [
          {
            value: "diferenciación_mesenquimatosa",
            label: "Diferenciación Mesenquimatosa",
            selected: selectedHistologia === "diferenciación_mesenquimatosa",
          },
          {
            value: "diferenciación_glandular",
            label: "Diferenciación Glandular",
            selected: selectedHistologia === "diferenciación_glandular",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "schwannoma_(neurilemona_neurinoma)":
        values = [
          {
            value: "schwannoma_celular",
            label: "Schwannoma Celular",
            selected: selectedHistologia === "schwannoma_celular",
          },
          {
            value: "schwannoma_plexiforme",
            label: "Schwannoma Plexiforme",
            selected: selectedHistologia === "schwannoma_plexiforme",
          },
          {
            value: "schwannoma_melanotic",
            label: "Schwannoma Melanotic",
            selected: selectedHistologia === "schwannoma_melanotic",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "tumores_de_tejido_neuroepitelial":
        values = [
          {
            value: "tumores_de_tejido_neuroepitelial",
            label: "Tumores de Tejido Neuroepitelial",
            selected: selectedHistologia === "tumores_de_tejido_neuroepitelial",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "tumores_astrocíticos":
        values = [
          {
            value: "tumores_astrocíticos",
            label: "Tumores Astrocíticos",
            selected: selectedHistologia === "tumores_astrocíticos",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "astrocitoma_pilocítico":
        values = [
          {
            value: "astrocitoma_pilomixoide",
            label: "Astrocitoma Pilomixoide",
            selected: selectedHistologia === "astrocitoma_pilomixoide",
          },
          {
            value: "astrocitoma_de_células_gigantes_subependimarias",
            label: "Astrocitoma de Células Gigantes Subependimarias",
            selected:
              selectedHistologia ===
              "astrocitoma_de_células_gigantes_subependimarias",
          },
          {
            value: "xanthoastrocitoma_pleomorfico",
            label: "Xanthoastrocitoma Pleomorfico",
            selected: selectedHistologia === "xanthoastrocitoma_pleomorfico",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "astrocitoma_difuso":
        values = [
          {
            value: "astrocitoma_fibrilar",
            label: "Astrocitoma Fibrilar",
            selected: selectedHistologia === "astrocitoma_fibrilar",
          },
          {
            value: "astrocitoma_gemistocítico",
            label: "Astrocitoma Gemistocítico",
            selected: selectedHistologia === "astrocitoma_gemistocítico",
          },
          {
            value: "astrocitoma_protoplasmático",
            label: "Astrocitoma Protoplasmático",
            selected: selectedHistologia === "astrocitoma_protoplasmático",
          },
          {
            value: "astrocitoma_anaplásico",
            label: "Astrocitoma Anaplásico",
            selected: selectedHistologia === "astrocitoma_anaplásico",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "glioblastoma":
        values = [
          {
            value: "glioblastoma_de_células_gigantes",
            label: "Glioblastoma de Células Gigantes",
            selected: selectedHistologia === "glioblastoma_de_células_gigantes",
          },
          {
            value: "gliosarcoma",
            label: "Gliosarcoma",
            selected: selectedHistologia === "gliosarcoma",
          },
          {
            value: "gliomatosis_cerebral",
            label: "Gliomatosis Cerebral",
            selected: selectedHistologia === "gliomatosis_cerebral",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "tumores_oligodendrogliales":
        values = [
          {
            value: "oligodentroglioma",
            label: "Oligodentroglioma",
            selected: selectedHistologia === "oligodentroglioma",
          },
          {
            value: "oligodentroglioma_anaplásico",
            label: "Oligodentroglioma Anaplásico",
            selected: selectedHistologia === "oligodentroglioma_anaplásico",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "tumores_ependimarios":
        values = [
          {
            value: "subependimoma",
            label: "Subependimoma",
            selected: selectedHistologia === "subependimoma",
          },
          {
            value: "ependimoma_mixopapilar",
            label: "Ependimoma Mixopapilar",
            selected: selectedHistologia === "ependimoma_mixopapilar",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "ependimoma":
        values = [
          {
            value: "ependimoma_celular",
            label: "Ependimoma Celular",
            selected: selectedHistologia === "ependimoma_celular",
          },
          {
            value: "ependimoma_papilar",
            label: "Ependimoma Papilar",
            selected: selectedHistologia === "ependimoma_papilar",
          },
          {
            value: "ependimoma_de_células_claras",
            label: "Ependimoma de Células Claras",
            selected: selectedHistologia === "ependimoma_de_células_claras",
          },
          {
            value: "ependimoma_tanycytic",
            label: "Ependimoma Tanycytic",
            selected: selectedHistologia === "ependimoma_tanycytic",
          },
          {
            value: "ependimoma_anaplásico",
            label: "Ependimoma Anaplásico",
            selected: selectedHistologia === "ependimoma_anaplásico",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "tumores_del_plexo_coroideo":
        values = [
          {
            value: "papiloma_del_plexo_coroideo_atítico",
            label: "Papiloma del plexo Coroideo Atítico",
            selected:
              selectedHistologia === "papiloma_del_plexo_coroideo_atítico",
          },
          {
            value: "carcinoma_del_plexo_coroideo",
            label: "Carcinoma del Plexo Coroideo",
            selected: selectedHistologia === "carcinoma_del_plexo_coroideo",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "tumores_neuronales_y_mixtos_neuronales_-_gliales":
        values = [
          {
            value: "gangliocitoma_displásico_cerebeloso_(lhermitte_duclos)",
            label: "Gangliocitoma displásico Cerebeloso (Lhermitte duclos)",
            selected:
              selectedHistologia ===
              "gangliocitoma_displásico_cerebeloso_(lhermitte_duclos)",
          },
          {
            value: "astrocitoma_infantil_desmoplásico_(ganglioglioma)",
            label: "Astrocitoma Infantil Desmoplásico (Ganglioglioma)",
            selected:
              selectedHistologia ===
              "astrocitoma_infantil_desmoplásico_(ganglioglioma)",
          },
          {
            value: "tumor_neuroepitelial_disembrioplástico",
            label: "Tumor Neuroepitelial Disembrioplástico",
            selected:
              selectedHistologia === "tumor_neuroepitelial_disembrioplástico",
          },
          {
            value: "gangliocitoma",
            label: "Gangliocitoma",
            selected: selectedHistologia === "gangliocitoma",
          },
          {
            value: "ganglioglioma",
            label: "Ganglioglioma",
            selected: selectedHistologia === "ganglioglioma",
          },
          {
            value: "ganglioglioma_anaplásico",
            label: "Ganglioglioma Anaplásico",
            selected: selectedHistologia === "ganglioglioma_anaplásico",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "neurocitoma_central":
        values = [
          {
            value: "neurocitoma_extraventricular",
            label: "Neurocitoma Extraventricular",
            selected: selectedHistologia === "neurocitoma_extraventricular",
          },
          {
            value: "liponeurocitoma_cerebeloso",
            label: "Liponeurocitoma Cerebeloso",
            selected: selectedHistologia === "liponeurocitoma_cerebeloso",
          },
          {
            value: "tumor_glioneuronal_papilar",
            label: "Tumor Glioneuronal Papilar",
            selected: selectedHistologia === "tumor_glioneuronal_papilar",
          },
          {
            value:
              "tumor_glioneuronal_formador_de_roseta_del_cuarto_ventriculo",
            label:
              "Tumor Glioneuronal Formador de Roseta del cuarto Ventriculo",
            selected:
              selectedHistologia ===
              "tumor_glioneuronal_formador_de_roseta_del_cuarto_ventriculo",
          },
          {
            value: "paraganglioma",
            label: "Paraganglioma",
            selected: selectedHistologia === "paraganglioma",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "tumores_de_la_región_pineal":
        values = [
          {
            value: "pineocitoma",
            label: "Pineocitoma",
            selected: selectedHistologia === "pineocitoma",
          },
          {
            value: "tumor_del_paénquima_pineal_diferenciación_intermedia",
            label: "Tumor del Paénquima Pineal Diferenciación Intermedia",
            selected:
              selectedHistologia ===
              "tumor_del_paénquima_pineal_diferenciación_intermedia",
          },
          {
            value: "pineoblastoma",
            label: "Pineoblastoma",
            selected: selectedHistologia === "pineoblastoma",
          },
          {
            value: "tumor_papilar_de_la_región_pineal",
            label: "Tumor Papilar de la Región Pineal",
            selected:
              selectedHistologia === "tumor_papilar_de_la_región_pineal",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "tumores_embrionarios":
        values = [
          {
            value: "tumores_embrionarios",
            label: "Tumores Embrionarios",
            selected: selectedHistologia === "tumores_embrionarios",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "meduloblastoma":
        values = [
          {
            value: "meduloblastoma_desmoplásico_nodular",
            label: "Meduloblastoma Desmoplásico Nodular",
            selected:
              selectedHistologia === "meduloblastoma_desmoplásico_nodular",
          },
          {
            value: "meduloblastoma_con_nodularidad_extensa",
            label: "Meduloblastoma con Nodularidad Extensa",
            selected:
              selectedHistologia === "meduloblastoma_con_nodularidad_extensa",
          },
          {
            value: "meduloblastoma_anaplásico",
            label: "Meduloblastoma Anaplásico",
            selected: selectedHistologia === "meduloblastoma_anaplásico",
          },
          {
            value: "meduloblastoma_de_células_grandes",
            label: "Meduloblastoma de Células Grandes",
            selected:
              selectedHistologia === "meduloblastoma_de_células_grandes",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "tumores_neuroectodérmico_primitivo":
        values = [
          {
            value: "ganglioneuroblastoma_del_snc",
            label: "Ganglioneuroblastoma del SNC",
            selected: selectedHistologia === "ganglioneuroblastoma_del_snc",
          },
          {
            value: "meduloepitelioma",
            label: "Meduloepitelioma",
            selected: selectedHistologia === "meduloepitelioma",
          },
          {
            value: "ependimoblastoma",
            label: "Ependimoblastoma",
            selected: selectedHistologia === "ependimoblastoma",
          },
          {
            value: "tumor_teratoide_rabdoideo_atípico_(TRTA)",
            label: "Tumor Teratoide Rabdoideo Atípico (TRTA)",
            selected:
              selectedHistologia === "tumor_teratoide_rabdoideo_atípico_(TRTA)",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "tumores_de_las_meninges":
        values = [
          {
            value: "tumores_de_las_meninges",
            label: "Tumores de las Meninges",
            selected: selectedHistologia === "tumores_de_las_meninges",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "tumores_de_células_meningoteliales":
        values = [
          {
            value: "tumores_de_células_meningoteliales",
            label: "Tumores de Células Meningoteliales",
            selected:
              selectedHistologia === "tumores_de_células_meningoteliales",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "meningioma_benigno":
        values = [
          {
            value: "meningioma_meningotelial_benigno",
            label: "Meningioma Meningotelial Benigno",
            selected: selectedHistologia === "meningioma_meningotelial_benigno",
          },
          {
            value: "meningioma_transicional_(mixto)_benigno",
            label: "Meningioma Transicional (Mixto) Benigno",
            selected:
              selectedHistologia === "meningioma_transicional_(mixto)_benigno",
          },
          {
            value: "meningioma_angiomatoso_benigno",
            label: "Meningioma Angiomatoso Benigno",
            selected: selectedHistologia === "meningioma_angiomatoso_benigno",
          },
          {
            value: "meningioma_microquístico_benigno",
            label: "Meningioma Microquístico Benigno",
            selected: selectedHistologia === "meningioma_microquístico_benigno",
          },
          {
            value: "meningioma_secretor_benigno",
            label: "Meningioma Secretor Benigno",
            selected: selectedHistologia === "meningioma_secretor_benigno",
          },
          {
            value: "meningioma_rico_en_linfoplasmocitos_benigno",
            label: "Meningioma Rico en Linfoplasmocitos Benigno",
            selected:
              selectedHistologia ===
              "meningioma_rico_en_linfoplasmocitos_benigno",
          },
          {
            value: "meningioma_metaplásico_benigno",
            label: "Meningioma Metaplásico Benigno",
            selected: selectedHistologia === "meningioma_metaplásico_benigno",
          },
          {
            value: "meningioma_de_células_claras_intracraneales",
            label: "Meningioma de Células claras Intracraneales",
            selected:
              selectedHistologia ===
              "meningioma_de_células_claras_intracraneales",
          },
          {
            value: "meningioma_atípico",
            label: "Meningioma Atípico",
            selected: selectedHistologia === "meningioma_atípico",
          },
          {
            value: "meningioma_papilar",
            label: "Meningioma Papilar",
            selected: selectedHistologia === "meningioma_papilar",
          },
          {
            value: "meningioma_rabdoideo",
            label: "Meningioma Rabdoideo",
            selected: selectedHistologia === "meningioma_rabdoideo",
          },
          {
            value: "meningioma_anaplásico_maligno",
            label: "Meningioma Anaplásico Maligno",
            selected: selectedHistologia === "meningioma_anaplásico_maligno",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "otras_neoplasias_relacionadas_con_las_meninges":
        values = [
          {
            value: "hemangioblastoma_benigno",
            label: "Hemangioblastoma Benigno",
            selected: selectedHistologia === "hemangioblastoma_benigno",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "linfomas_y_neoplasias_hematopoyéticas":
        values = [
          {
            value: "linfomas_malignos",
            label: "Linfomas Malignos",
            selected: selectedHistologia === "linfomas_malignos",
          },
          {
            value: "plasmocitoma_benigno",
            label: "Plasmocitoma Benigno",
            selected: selectedHistologia === "plasmocitoma_benigno",
          },
          {
            value: "plasmocitoma_maligno",
            label: "Plasmocitoma Maligno",
            selected: selectedHistologia === "plasmocitoma_maligno",
          },
          {
            value: "sarcoma_granulocítico_malignos",
            label: "Sarcoma Granulocítico Malignos",
            selected: selectedHistologia === "sarcoma_granulocítico_malignos",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "tumores_de_células_germinales":
        values = [
          {
            value: "germinoma",
            label: "Germinoma",
            selected: selectedHistologia === "germinoma",
          },
          {
            value: "carcinoma_embrionario",
            label: "Carcinoma Embrionario",
            selected: selectedHistologia === "carcinoma_embrionario",
          },
          {
            value: "cariocarcinoma",
            label: "Cariocarcinoma",
            selected: selectedHistologia === "cariocarcinoma",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "teratoma":
        values = [
          {
            value: "teratoma_maduro_benigno",
            label: "Teratoma Maduro Benigno",
            selected: selectedHistologia === "teratoma_maduro_benigno",
          },
          {
            value: "teratoma_inmaduro_maligno",
            label: "Teratoma Inmaduro Maligno",
            selected: selectedHistologia === "teratoma_inmaduro_maligno",
          },
          {
            value: "teratoma_maligno",
            label: "Teratoma Maligno",
            selected: selectedHistologia === "teratoma_maligno",
          },
          {
            value: "tumores_de_células_germinales_mixtas_benigno",
            label: "Tumores de Células Germinales Mixtas Benigno",
            selected:
              selectedHistologia ===
              "tumores_de_células_germinales_mixtas_benigno",
          },
          {
            value: "tumores_de_células_germinales_mixtas_maligno",
            label: "Tumores de Células Germinales Mixtas Maligno",
            selected:
              selectedHistologia ===
              "tumores_de_células_germinales_mixtas_maligno",
          },
          {
            value: "tumores_del_saco_vitelino",
            label: "Tumores del Saco Vitelino",
            selected: selectedHistologia === "tumores_del_saco_vitelino",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "tumores_de_la_región_selar":
        values = [
          {
            value: "craneofaringioma_benigno",
            label: "Craneofaringioma Benigno",
            selected: selectedHistologia === "craneofaringioma_benigno",
          },
          {
            value: "craneofaringioma_maligno",
            label: "Craneofaringioma Maligno",
            selected: selectedHistologia === "craneofaringioma_maligno",
          },
          {
            value: "craneofaringioma_adamantinomatoso",
            label: "Craneofaringioma Adamantinomatoso",
            selected:
              selectedHistologia === "craneofaringioma_adamantinomatoso",
          },
          {
            value: "craneofaringioma_papilar",
            label: "Craneofaringioma Papilar",
            selected: selectedHistologia === "craneofaringioma_papilar",
          },
          {
            value: "tumor_de_células_granulares",
            label: "Tumor de Células Granulares",
            selected: selectedHistologia === "tumor_de_células_granulares",
          },
          {
            value: "pituicitoma_benigno",
            label: "Pituicitoma Benigno",
            selected: selectedHistologia === "pituicitoma_benigno",
          },
          {
            value: "oncocitoma_de_células_fusiformes_de_la_adenohipofisis",
            label: "Oncocitoma de Células Fusiformes de la Adenohipofisis",
            selected:
              selectedHistologia ===
              "oncocitoma_de_células_fusiformes_de_la_adenohipofisis",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "astroblastoma":
        values = [
          {
            value: "astroblastoma",
            label: "Astroblastoma",
            selected: selectedHistologia === "astroblastoma",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "glioma_cordoideo_del_tercer_ventrículo":
        values = [
          {
            value: "glioma_cordoideo_del_tercer_ventrículo",
            label: "Glioma Cordoideo del Tercer Ventrículo",
            selected:
              selectedHistologia === "glioma_cordoideo_del_tercer_ventrículo",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "glioma_angiocéntrico":
        values = [
          {
            value: "glioma_angiocéntrico",
            label: "Glioma Angiocéntrico",
            selected: selectedHistologia === "glioma_angiocéntrico",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "neurofibroma_plexiforme":
        values = [
          {
            value: "neurofibroma_plexiforme",
            label: "Neurofibroma Plexiforme",
            selected: selectedHistologia === "neurofibroma_plexiforme",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "liposarcoma":
        values = [
          {
            value: "liposarcoma",
            label: "Liposarcoma ",
            selected: selectedHistologia === "liposarcoma",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "condroma":
        values = [
          {
            value: "condroma",
            label: "Condroma",
            selected: selectedHistologia === "condroma",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "hemangioendotelioma_epitelioide":
        values = [
          {
            value: "hemangioendotelioma_epitelioide",
            label: "Hemangioendotelioma Epitelioide",
            selected: selectedHistologia === "hemangioendotelioma_epitelioide",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "angiosarcoma":
        values = [
          {
            value: "angiosarcoma",
            label: "Angiosarcoma",
            selected: selectedHistologia === "angiosarcoma",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "sarcoma_de_kaposi":
        values = [
          {
            value: "sarcoma_de_kaposi",
            label: "Sarcoma de Kaposi",
            selected: selectedHistologia === "sarcoma_de_kaposi",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "melanomatosis_meningea":
        values = [
          {
            value: "melanomatosis_meningea",
            label: "Melanomatosis Meningea",
            selected: selectedHistologia === "melanomatosis_meningea",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "cordoma":
        values = [
          {
            value: "cordoma",
            label: "Cordoma",
            selected: selectedHistologia === "cordoma",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "sin_diagnóstico_confirmado_sistema_nervioso_central":
        values = [
          {
            value: "sin_diagnóstico_confirmado_sistema_nervioso_central",
            label: "Sin Diagnóstico Confirmado Sistema Nervioso Central",
            selected:
              selectedHistologia ===
              "sin_diagnóstico_confirmado_sistema_nervioso_central",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "rabdomiosarcoma":
        values = [
          {
            value: "alveolar",
            label: "Alveolar",
            selected: selectedHistologia === "alveolar",
          },
          {
            value: "embrionario",
            label: "Embrionario",
            selected: selectedHistologia === "embrionario",
          },
          {
            value: "botrioide",
            label: "Botrioide",
            selected: selectedHistologia === "botrioide",
          },
          {
            value: "plomorfo",
            label: "Plomorfo",
            selected: selectedHistologia === "plomorfo",
          },
          {
            value: "pleomorfo",
            label: "Pleomorfo",
            selected: selectedHistologia === "pleomorfo",
          },
          {
            value: "indiferenciado",
            label: "Indiferenciado",
            selected: selectedHistologia === "indiferenciado",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "sarcoma_no_rabdomiosarcoma":
        values = [
          {
            value: "sarcoma_sinovial",
            label: "Sarcoma Sinovial",
            selected: selectedHistologia === "sarcoma_sinovial",
          },
          {
            value: "histiocitoma_fibroso_maligno",
            label: "Histiocitoma Fibroso Maligno",
            selected: selectedHistologia === "histiocitoma_fibroso_maligno",
          },
          {
            value: "tumor_maligno_de_la_vaina_del_nervio_periférico",
            label: "Tumor Maligno de la Vaina del Nervio Periférico",
            selected:
              selectedHistologia ===
              "tumor_maligno_de_la_vaina_del_nervio_periférico",
          },
          {
            value: "fibrosarcoma",
            label: "Fibrosarcoma",
            selected: selectedHistologia === "fibrosarcoma",
          },
          {
            value: "leiomiosarcoma",
            label: "Leiomiosarcoma",
            selected: selectedHistologia === "leiomiosarcoma",
          },
          {
            value: "sarcoma_epitelioide",
            label: "Sarcoma Epitelioide",
            selected: selectedHistologia === "sarcoma_epitelioide",
          },
          {
            value: "sarcoma_de_la_parte_blanda_alveolar",
            label: "Sarcoma de la Parte Blanda Alveolar",
            selected:
              selectedHistologia === "sarcoma_de_la_parte_blanda_alveolar",
          },
          {
            value: "hemangiopericitoma",
            label: "Hemangiopericitoma",
            selected: selectedHistologia === "hemangiopericitoma",
          },
          {
            value: "sarcoma_de_tejidos_blandos",
            label: "Sarcoma de Tejidos Blandos",
            selected: selectedHistologia === "sarcoma_de_tejidos_blandos",
          },
          {
            value: "sarcoma_de_tejidos_blandos_no_especificado_de_otra_manera",
            label: "Sarcoma de Tejido Blandos, no Especificado de otra Manera",
            selected:
              selectedHistologia ===
              "sarcoma_de_tejidos_blandos_no_especificado_de_otra_manera",
          },
          {
            value: "condrosarcoma",
            label: "Condrosarcoma",
            selected: selectedHistologia === "condrosarcoma",
          },
          {
            value: "sarcoma_de_tejidos_blandos_células_claras",
            label: "Sarcoma de Tejidos Blandos, Células Claras",
            selected:
              selectedHistologia ===
              "sarcoma_de_tejidos_blandos_células_claras",
          },
          {
            value: "schwannoma_maligno",
            label: "Schwannoma Maligno",
            selected: selectedHistologia === "schwannoma_maligno",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "tumor_rabdoideo_maligno_de_tejidos_blandos",
            label: "Tumor Rabdoideo Maligno de Tejidos Blandos",
            selected:
              selectedHistologia ===
              "tumor_rabdoideo_maligno_de_tejidos_blandos",
          },
          {
            value: "tumor_desmoplásico_de_células_redondas_pequeñas",
            label: "Tumor Desmoplásico de Células Redondas Pequeñas",
            selected:
              selectedHistologia ===
              "tumor_desmoplásico_de_células_redondas_pequeñas",
          },
          {
            value: "fibrosarcoma_infantil_congénito",
            label: "Fibrosarcoma Infantil Congénito",
            selected: selectedHistologia === "fibrosarcoma_infantil_congénito",
          },
          {
            value:
              "proliferación_fibroblástica_-_miofibroblástica_de_la_infancia",
            label:
              "Proliferación Fibroblástica - Miofibroblástica de la Infancia",
            selected:
              selectedHistologia ===
              "proliferación_fibroblástica_-_miofibroblástica_de_la_infancia",
          },
          {
            value: "tumor_miofibroblástico_inflamatorio",
            label: "Tumor Miofibroblástico Inflamatorio",
            selected:
              selectedHistologia === "tumor_miofibroblástico_inflamatorio",
          },
          {
            value: "fibromatosis_de_tipo_desmoide",
            label: "Fibromatosis de Tipo Desmoide",
            selected: selectedHistologia === "fibromatosis_de_tipo_desmoide",
          },
          {
            value: "sarcoma_de_células_claras",
            label: "Sarcoma de Células Claras",
            selected: selectedHistologia === "sarcoma_de_células_claras",
          },
          {
            value: "pleomorfo",
            label: "Pleomorfo",
            selected: selectedHistologia === "pleomorfo",
          },
          {
            value: "cáncer_de_origen_primario_desconocido",
            label: "Cáncer de Origen Primario Desconocido",
            selected:
              selectedHistologia === "cáncer_de_origen_primario_desconocido",
          },
          {
            value: "dermatofibrosarcoma",
            label: "Dermatofibrosarcoma",
            selected: selectedHistologia === "dermatofibrosarcoma",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "sin_diagnóstico_confirmado_tumores_de_partes_blandas":
        values = [
          {
            value: "sin_diagnóstico_confirmado_tumores_de_partes_blandas",
            label: "Sin Diagnóstico Confirmado Tumores de Partes Blandas",
            selected:
              selectedHistologia ===
              "sin_diagnóstico_confirmado_tumores_de_partes_blandas",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "tumor_histiocítico":
        values = [
          {
            value: "histiocitosis_de_células_de_langerhans",
            label: "Histiocitosis de Células de Langerhans",
            selected:
              selectedHistologia === "histiocitosis_de_células_de_langerhans",
          },
          {
            value: "histiocitosis_maligna_reticulosis_medular_histiocitica",
            label: "Histiocitosis Maligna, Reticulosis Medular Histiocitica",
            selected:
              selectedHistologia ===
              "histiocitosis_maligna_reticulosis_medular_histiocitica",
          },
          {
            value: "linfohistiocitosis_hemafagocítica",
            label: "Linfohistiocitosis Hemafagocítica",
            selected:
              selectedHistologia === "linfohistiocitosis_hemafagocítica",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "sin_diagnóstico_confirmado_tumores_histiocítico":
        values = [
          {
            value: "sin_diagnóstico_confirmado_tumores_histiocítico",
            label: "Sin Diagnóstico Confirmado Tumores Histiocítico",
            selected:
              selectedHistologia ===
              "sin_diagnóstico_confirmado_tumores_histiocítico",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "cáncer_de_piel":
        values = [
          {
            value: "carcinoma_de_piel",
            label: "Carcinoma de Piel",
            selected: selectedHistologia === "carcinoma_de_piel",
          },
          {
            value: "carcinoma_de_células_basales",
            label: "Carcinoma de Células Basales",
            selected: selectedHistologia === "carcinoma_de_células_basales",
          },
          {
            value: "melanoma",
            label: "Melanoma",
            selected: selectedHistologia === "melanoma",
          },
          {
            value:
              "tumor_gonadal_de_células_no_germinales_tumores_estromales_y_epiteliales",
            label:
              "Tumor Gonadal de Células no Germinales, Tumores Estromales y Epiteliales",
            selected:
              selectedHistologia ===
              "tumor_gonadal_de_células_no_germinales_tumores_estromales_y_epiteliales",
          },
          {
            value: "melanoma_maligno",
            label: "Melanoma Maligno",
            selected: selectedHistologia === "melanoma_maligno",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "cáncer_de_origen_primario_desconocido":
        values = [
          {
            value: "cáncer_de_origen_primario_desconocido",
            label: "Cáncer de Origen Primario Desconocido",
            selected:
              selectedHistologia === "cáncer_de_origen_primario_desconocido",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "mesotelioma":
        values = [
          {
            value: "mesotelioma",
            label: "Mesotelioma",
            selected: selectedHistologia === "mesotelioma",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "pancreatoblastoma":
        values = [
          {
            value: "Pancreatoblastoma",
            label: "Pancreatoblastoma",
            selected: selectedHistologia === "Pancreatoblastoma",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "blastoma_pleuropulmonar":
        values = [
          {
            value: "blastoma_pleuropulmonar",
            label: "Blastoma Pleuropulmonar",
            selected: selectedHistologia === "blastoma_pleuropulmonar",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "sin_diagnóstico_confirmado_tumores_raros":
        values = [
          {
            value: "sin_diagnóstico_confirmado_tumores_raros",
            label: "Sin Diagnóstico Confirmado Tumores Raros",
            selected:
              selectedHistologia === "sin_diagnóstico_confirmado_tumores_raros",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "carcinoma_de_parótida":
        values = [
          {
            value: "carcinoma_de_parótida",
            label: "Carcinoma de Parótida",
            selected: selectedHistologia === "carcinoma_de_parótida",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "carcinoma_de_tiroides":
        values = [
          {
            value: "folicular",
            label: "Folicular",
            selected: selectedHistologia === "folicular",
          },
          {
            value: "papilar",
            label: "Papilar",
            selected: selectedHistologia === "papilar",
          },
          {
            value: "medular",
            label: "Medular",
            selected: selectedHistologia === "medular",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "carcinoma_otros":
        values = [
          {
            value: "carcinoma_nasofaríngeo",
            label: "Carcinoma Nasofaríngeo",
            selected: selectedHistologia === "carcinoma_nasofaríngeo",
          },
          {
            value: "carcinoma_de_colon",
            label: "Carcinoma de Colon",
            selected: selectedHistologia === "carcinoma_de_colon",
          },
          {
            value: "carcinoma_de_mama",
            label: "Carcinoma de Mama",
            selected: selectedHistologia === "carcinoma_de_mama",
          },
          {
            value: "carcinoma_de_ovario",
            label: "Carcinoma de Ovario",
            selected: selectedHistologia === "carcinoma_de_ovario",
          },
          {
            value: "carcinoma_adrenocortical",
            label: "Carcinoma Adrenocortical",
            selected: selectedHistologia === "carcinoma_adrenocortical",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "tumores_de_glándulas_salivales_carcinoma":
        values = [
          {
            value: "carcinoma_de_glándulas_salivales",
            label: "Carcinoma de Glándulas Salivales",
            selected: selectedHistologia === "carcinoma_de_glándulas_salivales",
          },
          {
            value: "carcinoma_del_apéndice",
            label: "Carcinoma del Apéndice",
            selected: selectedHistologia === "carcinoma_del_apéndice",
          },
          {
            value: "carcinoma_de_pulmón",
            label: "Carcinoma de Pulmón",
            selected: selectedHistologia === "carcinoma_de_pulmón",
          },
          {
            value: "carcinoma_de_timo",
            label: "Carcinoma de Timo",
            selected: selectedHistologia === "carcinoma_de_timo",
          },
          {
            value: "carcinomas_ginecológicos",
            label: "Carcinomas Ginecológicos",
            selected: selectedHistologia === "carcinomas_ginecológicos",
          },
          {
            value: "carcinoma_de_células_escamosas",
            label: "Carcinoma de Células Escamosas",
            selected: selectedHistologia === "carcinoma_de_células_escamosas",
          },
          {
            value: "carcinoma_de_otros_sitios_especificados",
            label: "Carcinoma de Otros Sitios Especificados",
            selected:
              selectedHistologia === "carcinoma_de_otros_sitios_especificados",
          },
          {
            value: "carcinoma_de_línea_media_(nut)",
            label: "Carcinoma de Línea Media (NUT)",
            selected: selectedHistologia === "carcinoma_de_línea_media_(nut)",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "sin_diagnostico_confirmado_tumores_solidos":
        values = [
          {
            value: "sin_diagnostico_confirmado_tumores_solidos",
            label: "Sin Diagnóstico Confirmado Tumores Solidos",
            selected:
              selectedHistologia ===
              "sin_diagnostico_confirmado_tumores_solidos",
          },
          {
            value: "sin_histología",
            label: "Sin Histología",
            selected: selectedHistologia === "sin_histología",
          },
          {
            value: "sin_datos",
            label: "Sin Datos",
            selected: selectedHistologia === "sin_datos",
          },
        ];
        break;
      case "sin_diagnóstico":
        values = [
          {
            value: "sin_diagnóstico_confirmado",
            label: "Sin Diagnóstico Confirmado",
            selected: selectedHistologia === "sin_diagnóstico_confirmado",
          },
          {
            value: "diagnóstico_desconocido",
            label: "Diagnóstico Desconocido",
            selected: selectedHistologia === "diagnóstico_desconocido",
          },
          {
            value: "enfermedad_pendiente",
            label: "Enfermedad Pendiente",
            selected: selectedHistologia === "enfermedad_pendiente",
          },
        ];
        break;
      default:
        values = [
          {
            value: "seleccionar_enfermedad",
            label: "Seleccionar Enfermedad",
            selected: selectedHistologia === "seleccionar_enfermedad",
          },
        ];
    }
    return values;
  }
}
