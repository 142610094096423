import { Controller } from "stimulus";

let chart5 = null;
let map = null;
export default class extends Controller {
  static targets = ["text", "departaments"];

  connect() {
    chart5 = Chartkick.charts["chart-5"];

    if (this.hasDepartamentsTarget) {
      map = am4core.create(this.departamentsTarget, am4maps.MapChart);
      this.renderMap();
    }
  }

  async renderMap() {
    // let map = am4core.create(this.departamentsTarget, am4maps.MapChart);
    map.geodata = am4geodata_hondurasLow;
    map.projection = new am4maps.projections.Miller();
    map.seriesContainer.draggable = false;
    map.seriesContainer.resizable = false;
    map.maxZoomLevel = 1;
    map.chartContainer.wheelable = false;

    let polygonSeries = new am4maps.MapPolygonSeries();
    polygonSeries.useGeodata = true;
    map.series.push(polygonSeries);

    let path = this.departamentsTarget.dataset.path;

    let response = await fetch(path);
    let information = await response.json();

    polygonSeries.data = [
      {
        id: "HN-AT",
        value: information["atlántida"],
        fill: am4core.color("#ABDEE6"),
      },
      {
        id: "HN-CH",
        value: information["choluteca"],
        fill: am4core.color("#CBAACB"),
      },
      {
        id: "HN-CL",
        value: information["colón"],
        fill: am4core.color("#FFFFB5"),
      },
      {
        id: "HN-CM",
        value: information["comayagua"],
        fill: am4core.color("#FFCCB6"),
      },
      {
        id: "HN-CP",
        value: information["copán"],
        fill: am4core.color("#F3B0C3"),
      },
      {
        id: "HN-CR",
        value: information["cortés"],
        fill: am4core.color("#C6DBDA"),
      },
      {
        id: "HN-EP",
        value: information["el_paraíso"],
        fill: am4core.color("#D4F0F0"),
      },
      {
        id: "HN-FM",
        value: information["francisco_morazán"],
        fill: am4core.color("#8FCACA"),
      },
      {
        id: "HN-GD",
        value: information["gracias_a_dios"],
        fill: am4core.color("#B6CFB6"),
      },
      {
        id: "HN-IN",
        value: information["intibucá"],
        fill: am4core.color("#97C1A9"),
      },
      {
        id: "HN-IB",
        value: information["islas_de_la_bahía"],
        fill: am4core.color("#FCB9AA"),
      },
      {
        id: "HN-LP",
        value: information["la_paz"],
        fill: am4core.color("#FFDBCC"),
      },
      {
        id: "HN-LM",
        value: information["lempira"],
        fill: am4core.color("#ECEAE4"),
      },
      {
        id: "HN-OC",
        value: information["ocotepeque"],
        fill: am4core.color("#55CBCD"),
      },
      {
        id: "HN-OL",
        value: information["olancho"],
        fill: am4core.color("#FFC5BF"),
      },
      {
        id: "HN-SB",
        value: information["santa_bárbara"],
        fill: am4core.color("#FFC8A2"),
      },
      {
        id: "HN-VA",
        value: information["valle"],
        fill: am4core.color("#ECD5E3"),
      },
      {
        id: "HN-YO",
        value: information["yoro"],
        fill: am4core.color("#FFAEA5"),
      },
    ];

    let polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.tooltipText = `{name}: {value}`;
    polygonTemplate.propertyFields.fill = "fill";
    let hs = polygonTemplate.states.create("hover");
    hs.properties.fill = am4core.color("#DFC05D");

    // let indicator = map.tooltipContainer.createChild(am4core.Container);
    // indicator.background.fill = am4core.color("#fff");
    // indicator.width = am4core.percent(100);
    // indicator.height = am4core.percent(100);

    // var indicatorLabel = indicator.createChild(am4core.Label);
    // indicatorLabel.text = "Cargando...";
    // indicatorLabel.align = "center";
    // indicatorLabel.valign = "middle";
    // indicatorLabel.dy = 10;

    // map.events.on("ready", function(ev){
    //   indicator.hide();
    // });

    map.responsive.enabled = true;
  }

  togglePatientGroup(event) {
    const id = event.target.id;
    const path = event.target.dataset.path;
    chart5.updateData(path);

    if (id == "patient-groupby-month") {
      this.textTarget.textContent = "Últimos 6 meses";
    } else {
      this.textTarget.textContent = "Últimas 6 semanas";
    }
  }

  disconnect() {
    if (chart5) chart5.destroy();
    if (map) map.dispose();
  }
}
