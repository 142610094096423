import { Controller } from "stimulus";
import { slideUp, slideDown } from "./utils";

export default class extends Controller {
  static targets = [
    "otherDiagnosticMethod",
    "otherDiseaseSite",
    "otherTopographyCode",
    "notAssessableReason",
    "otherAssessableReason",
    "otherSarcomaTopographyClassification",
    "otherBrainTumorLocalization"
  ];

  handleOtherDiagnosticMethod(event) {
    let target = document.getElementById(event.target.dataset.div);

    if (event.target.value === "Otro") {
      slideDown(target);
    } else {
      slideUp(target);
      this.otherDiagnosticMethodTarget.value = "";
    }
  }

  handleOtherDiseaseSite(event) {
    let target = document.getElementById(event.target.dataset.div);

    if (event.target.value === "Otro") {
      slideDown(target);
    } else {
      slideUp(target);
      this.otherDiseaseSiteTarget.value = "";
    }
  }

  handleOtherTopographyCode(event) {
    let target = document.getElementById(event.target.dataset.div);

    if (event.target.value === "Otros" || event.target.value === "Otro") {
      slideDown(target);
    } else {
      slideUp(target);
      this.otherTopographyCodeTarget.value = "";
    }
  }

  handleIsAssessable(event) {
    let target = document.getElementById(event.target.dataset.div);
    let target2 = document.getElementById("not-assessable-reason-other");

    if (event.target.value === "false") {
      slideDown(target);
    } else {
      slideUp(target);
      slideUp(target2);
      this.notAssessableReasonTarget.value = "";
      this.otherAssessableReasonTarget.value = "";
    }
  }

  handleIsAssessableReasonOther(event) {
    let target = document.getElementById(event.target.dataset.div);

    if (event.target.value.includes("Otra Patología")) {
      slideDown(target);
    } else {
      slideUp(target);
      this.otherAssessableReasonTarget.value = "";
    }
  }

  handleOtherTopographyClassification(event) {
    let target = document.getElementById(event.target.dataset.div);

    if (event.target.value.includes("Otros")) {
      slideDown(target);
    } else {
      slideUp(target);
      this.otherSarcomaTopographyClassificationTarget.value = "";
    }
  }

  handleOtherBrainTumorLocalization(event) {
    let target = document.getElementById(event.target.dataset.div);

    if (event.target.value.includes("Otros")) {
      slideDown(target);
    } else {
      slideUp(target);
      this.otherBrainTumorLocalizationTarget.value = "";
    }
  }
}
