import ApplicationController from "./application_controller";
import { slideUp, slideDown } from "./utils";
let date = new Date().getTime();
let cita_slug = "";
let physician_slug = "";

export default class extends ApplicationController {
  static targets = ["form", "cancelButton", "otroEspecialidad"];
  static values = { physician: String };

  connect() {
    super.connect();
  }

  initialize() {
    if (this.hasPhysicianValue) {
      physician_slug = this.physicianValue;
    }
  }

  handleOtroEspecialidad(event) {
    if (this.hasOtroEspecialidadTarget) {
      let target = document.getElementById(event.target.dataset.div);
      if (event.target.value === "Otro") {
        slideDown(target);
        this.otroEspecialidadTarget.setAttribute("required", "");
      } else {
        slideUp(target);
        this.otroEspecialidadTarget.removeAttribute("required");
        this.otroEspecialidadTarget.value = "";
      }
    }
  }

  hideShowPartial(event) {
    let target = document.getElementById(event.target.dataset.div);

    if (event.target.checked) {
      slideDown(target);
    } else {
      slideUp(target);
    }
  }

  handlePhysicianChange(event) {
    physician_slug = event.target.value;
    cita_slug = event.target.dataset.cita_slug;

    this.stimulate(
      "Citas#handle_physician_change",
      physician_slug,
      cita_slug,
      date
    );
  }

  handleDateChange(event) {
    date = new Date(event.target.value).getTime();

    if (physician_slug?.length > 0) {
      this.stimulate(
        "Citas#handle_physician_change",
        physician_slug,
        cita_slug,
        date
      )
        .then(() => console.log("success"))
        .catch((error) => console.error("error: ", error));
    }
  }

  handleSubmit(event) {
    if (
      confirm("Médico a superado límite de pacientes por día. ¿Desea proceder?")
    ) {
      console.log("ok");
    } else {
      event.preventDefault();
    }
  }
}
