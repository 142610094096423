import ApplicationController from "./application_controller";
import debounced from "debounced";

export default class extends ApplicationController {
  static targets = [
    "weight",
    "caloriesVct",
    "caloriesKcalD",
    "caloriesKcalKg",
    "proteinsVct",
    "proteinsKcalD",
    "proteinsKcalKg",
    "proteinsGD",
    "proteinsGKg",
    "fatsVct",
    "fatsKcalD",
    "fatsKcalKg",
    "fatsGD",
    "fatsGKg",
    "carbsVct",
    "carbsKcalD",
    "carbsKcalKg",
    "carbsGD",
    "carbsGKg",
  ];

  connect() {
    debounced.initialize({ input: { wait: 500 } });
  }

  calculateAll() {
    this.calculateCalories();
    this.calculateProteins();
    this.calculateFats();
    this.calculateCarbs();
  }
  calculateCalories() {
    this.calculateCaloriesKcalKg();
  }

  calculateProteins() {
    this.calculateProteinsKcalD();
    this.calculateProteinsKcalKg();
    this.calculateProteinsGD();
    this.calculateProteinsGKg();
  }

  calculateFats() {
    this.calculateFatsKcalD();
    this.calculateFatsKcalKg();
    this.calculateFatsGD();
    this.calculateFatsGKg();
  }

  calculateCarbs() {
    this.calculateCarbsKcalD();
    this.calculateCarbsKcalKg();
    this.calculateCarbsGD();
    this.calculateCarbsGKg();
  }

  calculateCaloriesKcalKg() {
    this.caloriesKcalKgTarget.value = this.round(
      Number(this.caloriesKcalDTarget.value) / Number(this.weightTarget.value)
    );
  }

  calculateProteinsKcalD() {
    this.proteinsKcalDTarget.value = this.round(
      Number(this.caloriesKcalDTarget.value) *
        Number(this.proteinsVctTarget.value / 100)
    );
  }

  calculateProteinsKcalKg() {
    this.proteinsKcalKgTarget.value = this.round(
      Number(this.proteinsKcalDTarget.value) / Number(this.weightTarget.value)
    );
  }

  calculateProteinsGD() {
    this.proteinsGDTarget.value = this.round(
      Number(this.proteinsKcalDTarget.value) / 4
    );
  }

  calculateProteinsGKg() {
    this.proteinsGKgTarget.value = this.round(
      Number(this.proteinsGDTarget.value) / Number(this.weightTarget.value)
    );
  }

  calculateFatsKcalD() {
    this.fatsKcalDTarget.value = this.round(
      Number(this.caloriesKcalDTarget.value) *
        Number(this.fatsVctTarget.value / 100)
    );
  }

  calculateFatsKcalKg() {
    this.fatsKcalKgTarget.value = this.round(
      Number(this.fatsKcalDTarget.value) / Number(this.weightTarget.value)
    );
  }

  calculateFatsGD() {
    this.fatsGDTarget.value = this.round(
      Number(this.fatsKcalDTarget.value) / 9
    );
  }

  calculateFatsGKg() {
    this.fatsGKgTarget.value = this.round(
      Number(this.fatsGDTarget.value) / Number(this.weightTarget.value)
    );
  }

  calculateCarbsKcalD() {
    this.carbsKcalDTarget.value = this.round(
      Number(this.caloriesKcalDTarget.value) *
        Number(this.carbsVctTarget.value / 100)
    );
  }

  calculateCarbsKcalKg() {
    this.carbsKcalKgTarget.value = this.round(
      Number(this.carbsKcalDTarget.value) / Number(this.weightTarget.value)
    );
  }

  calculateCarbsGD() {
    this.carbsGDTarget.value = this.round(
      Number(this.carbsKcalDTarget.value) / 4
    );
  }

  calculateCarbsGKg() {
    this.carbsGKgTarget.value = this.round(
      Number(this.carbsGDTarget.value) / Number(this.weightTarget.value)
    );
  }

  round(num) {
    return Math.round(num * 100) / 100;
  }
}
