import { Controller } from "stimulus";
import { slideDown, slideUp } from "./utils";

export default class extends Controller {
  static targets = [
    "examTypeOther",
    "dayEightExamType",
    "leukocytes",
    "blastsFspDay8",
    "blastsAbsoluteCount",
    "plateletCountPerCubicMm",
    "mchcGPerDl",
    "goodBadResponseDay8"
  ]

  handleExamTypeOther(event) {
    const input = this.examTypeOtherTarget.querySelector("input");

    if (["Otro"].includes(event.target.value)) {
      slideDown(this.examTypeOtherTarget);
      input.setAttribute("required", "");
    } else {
      slideUp(this.examTypeOtherTarget);
      input.removeAttribute("required");
      input.value = "";
    }
  }

  handleDayEightExamType(event) {
    if (["Respuesta a Prednisona Día 8"].includes(event.target.value)) {
      this.dayEightExamTypeTarget.classList.add("show")
      this.goodBadResponseDay8Target.setAttribute("required", "required")
    } else {
      this.dayEightExamTypeTarget.classList.remove("show")
      this.goodBadResponseDay8Target.removeAttribute("required")
    }
  }

  calculateLeukocytes(event) {
    this.leukocytesTarget.value = event.target.value * 1000
  }

  calculateBlastsAbsoluteCount() {
    const blastsFspDay8 = this.blastsFspDay8Target.value
    const leukocytes = this.leukocytesTarget.value
    this.blastsAbsoluteCountTarget.value = ((leukocytes * blastsFspDay8) / 100)
  }

  calculatePlateletCountPerCubicMm(event) {
    this.plateletCountPerCubicMmTarget.value = (event.target.value * 1000)
  }

  calculateMchcGPerDl(event) {
    this.mchcGPerDlTarget.value = (event.target.value / 10)
  }
}
