import { Controller } from "stimulus";
import { slideDown, slideUp } from "./utils";

export default class extends Controller {
  static targets = [
    "identidad",
    "identidadOutput",
    "identidadExtranjeroOutput",
    "otroSindromeDiagnostico",
    "otroCentroDiagnostico",
    "otroUbicacionDiagnostico",
    "otroProtocoloTipo",
    "otroProtocoloOficial",
    "stages",
  ];

  handleTieneIdentidad(event) {
    if (this.hasIdentidadTarget) {
      if (event.target.value == "true") {
        this.identidadTarget.setAttribute("required", "required");
        this.identidadOutputTarget.textContent = "Identidad *";
        this.identidadExtranjeroOutputTarget.textContent =
          "Identidad extranjero *";
      } else {
        this.identidadTarget.removeAttribute("required");
        this.identidadOutputTarget.textContent = "Identidad";
        this.identidadExtranjeroOutputTarget.textContent =
          "Identidad extranjero";
      }
    }
  }

  handleIdentidadExtranjera(event) {
    if (this.hasIdentidadTarget && event.target.value.length > 0) {
      this.identidadTarget.removeAttribute("required");
      this.identidadOutputTarget.textContent = "Identidad";
    } else {
      this.identidadTarget.setAttribute("required", "required");
      this.identidadOutputTarget.textContent = "Identidad *";
    }
  }

  handleOtroSindromeDiagnostico(event) {
    if (this.hasOtroSindromeDiagnosticoTarget) {
      let target = document.getElementById(event.target.dataset.div);
      if (event.target.value === "otro") {
        slideDown(target);
        this.otroSindromeDiagnosticoTarget.setAttribute("required", "");
      } else {
        slideUp(target);
        this.otroSindromeDiagnosticoTarget.removeAttribute("required");
        this.otroSindromeDiagnosticoTarget.value = "";
      }
    }
  }

  handleOtroCentroDiagnostico(event) {
    if (this.hasOtroCentroDiagnosticoTarget) {
      let target = document.getElementById(event.target.dataset.div);
      if (event.target.value === "otro") {
        slideDown(target);
        this.otroCentroDiagnosticoTarget.setAttribute("required", "");
      } else {
        slideUp(target);
        this.otroCentroDiagnosticoTarget.removeAttribute("required");
        this.otroCentroDiagnosticoTarget.value = "";
      }
    }
  }

  handleOtroUbicacionDiagnostico(event) {
    if (this.hasOtroUbicacionDiagnosticoTarget) {
      let target = document.getElementById(event.target.dataset.div);
      if (event.target.value === "otro") {
        slideDown(target);
        this.otroUbicacionDiagnosticoTarget.setAttribute("required", "");
      } else {
        slideUp(target);
        this.otroUbicacionDiagnosticoTarget.removeAttribute("required");
        this.otroUbicacionDiagnosticoTarget.value = "";
      }
    }
  }

  handleStages(event) {
    if (!["leucemias"].includes(event.target.value)) {
      slideDown(this.stagesTarget);
    } else {
      slideUp(this.stagesTarget);
    }
  }

  handleOtroProtocoloTipo(event) {
    const input = this.otroProtocoloTipoTarget.querySelector("input");

    if (["Otro"].includes(event.target.value)) {
      slideDown(this.otroProtocoloTipoTarget);
      input.setAttribute("required", "");
    } else {
      slideUp(this.otroProtocoloTipoTarget);
      input.removeAttribute("required");
      input.value = "";
    }
  }

  handleOtroProtocoloOficial(event) {
    const input = this.otroProtocoloOficialTarget.querySelector("input");

    if (["Otro"].includes(event.target.value)) {
      slideDown(this.otroProtocoloOficialTarget);
      input.setAttribute("required", "");
    } else {
      slideUp(this.otroProtocoloOficialTarget);
      input.removeAttribute("required");
      input.value = "";
    }
  }
}
