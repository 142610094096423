import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["icon", "text"];

  toggleSideBar() {
    document.getElementById("sidebarMenu").classList.toggle("hideSideBar");
    document.querySelector(".content").classList.toggle("expand");
    this.iconTarget.classList.toggle("fa-rotate-180");

    if (this.textTarget.textContent == "Esconder") {
      this.textTarget.textContent = "Mostar";
    } else {
      this.textTarget.textContent = "Esconder";
    }
  }
}
