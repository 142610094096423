import { Controller } from "stimulus";
import { slideDown, slideUp } from "./utils";

export default class extends Controller {
  static targets = ["stagingSystemOther", "stageOther", "stagingLetterOther"];

  handleStagingSystemOther(event) {
    const input = this.stagingSystemOtherTarget.querySelector("input");

    if (["Otro"].includes(event.target.value)) {
      slideDown(this.stagingSystemOtherTarget);
      input.setAttribute("required", "");
    } else {
      slideUp(this.stagingSystemOtherTarget);
      input.removeAttribute("required");
      input.value = "";
    }
  }

  handleStageOther(event) {
    const input = this.stageOtherTarget.querySelector("input");

    if (["Otro"].includes(event.target.value)) {
      slideDown(this.stageOtherTarget);
      input.setAttribute("required", "");
    } else {
      slideUp(this.stageOtherTarget);
      input.removeAttribute("required");
      input.value = "";
    }
  }

  handleStagingLetterOther(event) {
    const input = this.stagingLetterOtherTarget.querySelector("input");

    if (["Otro"].includes(event.target.value)) {
      slideDown(this.stagingLetterOtherTarget);
      input.setAttribute("required", "");
    } else {
      slideUp(this.stagingLetterOtherTarget);
      input.removeAttribute("required");
      input.value = "";
    }
  }
}
