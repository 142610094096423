import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "addItem", "template", "addItemButton"]

  add_association(event) {
    event.preventDefault();
    var content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, Math.floor(Math.random() * 20))
    this.addItemTarget.insertAdjacentHTML('beforeend', content)
  }

  remove_association(event) {
    event.preventDefault();
    let item = event.target.closest(".nested-fields")
    item.querySelector("input[name*='destroy']").value = 1
    item.style.display = 'none'
  }
}
