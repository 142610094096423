import { DataTable } from "simple-datatables";
import "simple-datatables/dist/style.css";

const historiaClinicaDataTable = () => {
  const historiaClinica = document.querySelector("#historia-clinica-table");
  if (historiaClinica) {
    const dataTable = new DataTable(historiaClinica, {
      paging: true,
      perPage: 25,
      perPageSelect: [25, 50, 75, 100],
      labels: {
        placeholder: "buscar...",
        perPage: "{select} por pág",
        info: "Mostrando {start} a {end} de {rows}",
      },
    });
  }
};

const usuariosDataTable = () => {
  const users = document.querySelector("#users-table");
  if (users) {
    const dataTable = new DataTable(users, {
      paging: true,
      perPage: 25,
      perPageSelect: [25, 50, 75, 100],
      labels: {
        placeholder: "buscar...",
        perPage: "{select} por pág",
        info: "Mostrando {start} a {end} de {rows}",
      },
    });
  }
};

const mainDataTable = () => {
  const main = document.querySelector("#main-data-table");
  if (main) {
    const dataTable = new DataTable(main, {
      paging: true,
      perPage: 25,
      perPageSelect: [25, 50, 75, 100],
      labels: {
        placeholder: "buscar...",
        perPage: "{select} por pág",
        info: "Mostrando {start} a {end} de {rows}",
      },
    });
  }
};

const standartDataTable = () => {
  const table = document.querySelector("#standard-data-table");
  if (table) {
    new DataTable(table, {
      searchable: false,
      perPageSelect: false,
      paging: false,
    });
  }
};

document.addEventListener("turbolinks:load", () => {
  historiaClinicaDataTable();
  usuariosDataTable();
  mainDataTable();
  standartDataTable();
});
