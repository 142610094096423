require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("custom/pwa");
require("@popperjs/core");
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "../stylesheets/application.scss";
import "controllers";
require("custom/simpleDataTable");
require("custom/choices");
require("custom/citasCalendar");
import "@fortawesome/fontawesome-free/js/all";
import "@fortawesome/fontawesome-free/css/all";
import "chartkick/chart.js";

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
