import { Controller } from "stimulus";
import { slideDown, slideUp } from "./utils";

export default class extends Controller {
  static targets = [
    "otro",
    "intoleranciasAlimentarias",
    "hospitalizationAreaPeripheral",
    "cancerRisk",
    "currentIntenviseCare",
    "gastrointestinalTractSymptom",
    "poorIntakeLastWeek",
    "weightloss",
    "malnutritionSigns",
    "screeningOutput",
    "suplementosNutricionales",
    "nutritionalRiskFactorsOther",
  ];

  handleOtro(event) {
    let target = document.getElementById(event.target.dataset.div);
    if (["otro", "si"].includes(event.target.value)) {
      slideDown(target);
      this.otroTarget.setAttribute("required", "");
    } else {
      slideUp(target);
      this.otroTarget.removeAttribute("required");
      this.otroTarget.value = "";
    }
  }

  handleIntoleranciasAlimentarias(event) {
    let target = document.getElementById(event.target.dataset.div);
    if (["otro", "si"].includes(event.target.value)) {
      slideDown(target);
      this.intoleranciasAlimentariasTarget.setAttribute("required", "");
    } else {
      slideUp(target);
      this.intoleranciasAlimentariasTarget.removeAttribute("required");
      this.intoleranciasAlimentariasTarget.value = "";
    }
  }

  handleHospitalizationArea(event) {
    let target = document.getElementById(event.target.dataset.div);

    if (event.target.value === "Periférico") {
      slideDown(target);
      this.hospitalizationAreaPeripheralTarget.setAttribute("required", "");
    } else {
      slideUp(target);
      this.hospitalizationAreaPeripheralTarget.removeAttribute("required");
      this.hospitalizationAreaPeripheralTarget.value = "";
    }
  }

  handleSuplementosNutricionales(event) {
    let target = document.getElementById(event.target.dataset.div);

    if (event.target.value === "si") {
      slideDown(target);
      this.suplementosNutricionalesTarget.setAttribute("required", "");
    } else {
      slideUp(target);
      this.suplementosNutricionalesTarget.removeAttribute("required");
      this.suplementosNutricionalesTarget.value = "";
    }
  }

  handleNutritionalRiskFactorsOther(event) {
    let target = document.getElementById(event.target.dataset.div);
    let values = Array.from(
      event.target.selectedOptions,
      (option) => option.value
    );

    if (values.includes("otro")) {
      slideDown(target);
      this.nutritionalRiskFactorsOtherTarget.setAttribute("required", "");
    } else {
      slideUp(target);
      this.nutritionalRiskFactorsOtherTarget.removeAttribute("required");
      this.nutritionalRiskFactorsOtherTarget.value = "";
    }
  }

  handleScreeningOutput() {
    let sum = 0;
    sum +=
      Number(this.cancerRiskTarget.value) > 0
        ? Number(this.cancerRiskTarget.value)
        : 0;

    sum +=
      Number(this.currentIntenviseCareTarget.value) > 0
        ? Number(this.currentIntenviseCareTarget.value)
        : 0;

    sum +=
      Number(this.gastrointestinalTractSymptomTarget.value) > 0
        ? Number(this.gastrointestinalTractSymptomTarget.value)
        : 0;

    sum +=
      Number(this.poorIntakeLastWeekTarget.value) > 0
        ? Number(this.poorIntakeLastWeekTarget.value)
        : 0;

    sum +=
      Number(this.weightlossTarget.value) > 0
        ? Number(this.weightlossTarget.value)
        : 0;

    sum +=
      Number(this.malnutritionSignsTarget.value) > 0
        ? Number(this.malnutritionSignsTarget.value)
        : 0;

    this.screeningOutputTarget.textContent = sum;
  }
}
