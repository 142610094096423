import { Controller } from "stimulus";
let index_counter;

export default class extends Controller {
  static targets = ["addItem", "template", "addItemButton"];
  static values = {
    itemCount: Number,
  };

  connect() {
    this.checkNestedFieldAmounts();
    index_counter = 0;
  }

  add_association(event) {
    event.preventDefault();
    index_counter += 1;
    var content = this.templateTarget.innerHTML.replace(
      /TEMPLATE_RECORD/g,
      parseInt(event.target.dataset.childIndex) + index_counter
    );
    this.addItemTarget.insertAdjacentHTML("beforebegin", content);
    this.checkNestedFieldAmounts();
  }

  remove_association(event) {
    event.preventDefault();
    let item = event.target.closest(".nested-fields");
    item.querySelector("input[name*='destroy']").value = 1;
    item.style.display = "none";
    item.classList.add("removed");
    this.addItemButtonTarget.classList.remove("disabled");
  }

  checkNestedFieldAmounts() {
    const permittedAmount = this.itemCountValue || 5;
    const actualAmount = this.element.querySelectorAll(
      ".nested-fields:not(.removed)"
    ).length;

    if (actualAmount >= permittedAmount) {
      this.addItemButtonTarget.classList.add("disabled");
    }
  }
}
