import { Controller } from "stimulus";
import { slideDown, slideUp } from "./utils";

export default class extends Controller {
  static targets = ["tubeOther"];

  handleTubeOther(event) {
    const input = this.tubeOtherTarget.querySelector("input");

    if (["Otro"].includes(event.target.value)) {
      slideDown(this.tubeOtherTarget);
      input.setAttribute("required", "");
    } else {
      slideUp(this.tubeOtherTarget);
      input.removeAttribute("required");
      input.value = "";
    }
  }
}
