import { Controller } from "stimulus"

export default class extends Controller {
  	static targets = [ "addItem", "template", "addItemButton"]

  	connect() {
  		this.checkNestedFieldAmounts()
  	}

  	add_association(event) {
  		event.preventDefault();
  		var content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, Math.floor(Math.random() * 20))
  		this.addItemTarget.insertAdjacentHTML('beforeend', content)
  		this.checkNestedFieldAmounts()
  	}

  	remove_association(event) {
  		event.preventDefault();
  		let item = event.target.closest(".nested-fields")
  		item.querySelector("input[name*='destroy']").value = 1
  		item.style.display = 'none'
  		this.addItemButtonTarget.classList.remove("disabled")
  	}

  	checkNestedFieldAmounts() {
  		const permittedAmount = 3
  		const actualAmount = this.element.querySelectorAll('.nested-fields').length

  		if (actualAmount >= permittedAmount) {
  			this.addItemButtonTarget.classList.add("disabled")
  		}
  	}
}
