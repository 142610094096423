import { Controller } from "stimulus"

export default class extends Controller {
  	static targets = [ "dropdown" ]

	toggle(){
		const listElement = this.dropdownTarget
		listElement.classList.toggle("show")
	}
}
