import { Controller } from "stimulus";
import { slideDown, slideUp } from "./utils";

export default class extends Controller {
  static targets = [
    "relapse",
    "relapseOther",
    "startDate",
    "endDate",
    "statusDuration",
    "abandonmentReasonOther",
    "abandonmentPersonContactedOther",
    "outOfCountryPersonContactedOther",
    "refusalReasonOther",
    "refusalPersonContactedOther",
    "lossToFollowUpReasonReasonOther",
    "lossToFollowUpPersonContactedOther",
    "referredToOther",
    "transferredHospital",
    "transferredService",
    "deceasedReasonOther",
  ];

  handleRelapse(event) {
    let target = document.getElementById(event.target.dataset.div);
    let relapseOther = document.getElementById("status-relapse-other");

    if (["Recaída"].includes(event.target.value)) {
      slideDown(target);
      this.relapseTarget.setAttribute("required", "");
    } else {
      slideUp(target);
      slideUp(relapseOther);
      this.relapseTarget.removeAttribute("required");
      this.relapseTarget.value = "";
      this.relapseOtherTarget.removeAttribute("required");
      this.relapseOtherTarget.value = "";
    }
  }

  handleRelapseOther(event) {
    let target = document.getElementById(event.target.dataset.div);

    let values = Array.from(
      event.target.selectedOptions,
      (option) => option.value
    );

    if (values.includes("sitio_de_recaída_otro")) {
      slideDown(target);
      this.relapseOtherTarget.setAttribute("required", "");
    } else {
      slideUp(target);
      this.relapseOtherTarget.removeAttribute("required");
      this.relapseOtherTarget.value = "";
    }
  }

  handleStatusDuration() {
    let diff = Math.floor(
      (Date.parse(this.endDateTarget.value) -
        Date.parse(this.startDateTarget.value)) /
        86400000
    );
    this.statusDurationTarget.value = diff >= 0 ? diff : 0;
  }

  handleSituationRender(event) {
    let target = document.getElementById("patient-situation");
    let value = event.target.value;

    if (!["Activo"].includes(value)) {
      slideDown(target);
      this.handleSituationPartial(event.target.value);
    } else {
      slideUp(target);
    }
  }

  handleSituationPartial(value) {
    let possibleIds = [
      "abandonment",
      "out_of_country",
      "refusal",
      "loss_to_follow_up",
      "referred_to",
      "transferred_to",
      "deceased",
    ];
    let id;
    switch (value) {
      case "Abandono":
        id = "abandonment";
        break;
      case "Fuera Del País":
        id = "out_of_country";
        break;
      case "Rechazo":
        id = "refusal";
        break;
      case "Perdido en Seguimiento":
        id = "loss_to_follow_up";
        break;
      case "Referido A Otro Centro":
        id = "referred_to";
        break;
      case "Transferido a Hospital Extranjero":
        id = "transferred_to";
        break;
      case "Defunción":
        id = "deceased";
        break;
    }

    let target = document.getElementById(id);

    slideDown(target);

    let notPossibleIds = possibleIds.filter((v) => v !== id);

    for (const notPossibleId of notPossibleIds) {
      let targetToRemove = document.getElementById(notPossibleId);
      if (targetToRemove) slideUp(targetToRemove);
    }
  }

  handleAbandonmentReason(event) {
    let target = document.getElementById(event.target.dataset.div);

    if (["Otro"].includes(event.target.value)) {
      slideDown(target);
      this.abandonmentReasonOtherTarget.setAttribute("required", "");
    } else {
      slideUp(target);
      this.abandonmentReasonOtherTarget.removeAttribute("required");
      this.abandonmentReasonOtherTarget.value = "";
    }
  }

  handleAbandonmentPersonContacted(event) {
    let target = document.getElementById(event.target.dataset.div);

    if (["Otro"].includes(event.target.value)) {
      slideDown(target);
      this.abandonmentPersonContactedOtherTarget.setAttribute("required", "");
    } else {
      slideUp(target);
      this.abandonmentPersonContactedOtherTarget.removeAttribute("required");
      this.abandonmentPersonContactedOtherTarget.value = "";
    }
  }

  handleOutOfCountryPersonContacted(event) {
    let target = document.getElementById(event.target.dataset.div);

    if (["Otro"].includes(event.target.value)) {
      slideDown(target);
      this.outOfCountryPersonContactedOtherTarget.setAttribute("required", "");
    } else {
      slideUp(target);
      this.outOfCountryPersonContactedOtherTarget.removeAttribute("required");
      this.outOfCountryPersonContactedOtherTarget.value = "";
    }
  }

  handleRefusalReason(event) {
    let target = document.getElementById(event.target.dataset.div);

    if (["Otro"].includes(event.target.value)) {
      slideDown(target);
      this.refusalReasonOtherTarget.setAttribute("required", "");
    } else {
      slideUp(target);
      this.refusalReasonOtherTarget.removeAttribute("required");
      this.refusalReasonOtherTarget.value = "";
    }
  }

  handleRefusalPersonContacted(event) {
    let target = document.getElementById(event.target.dataset.div);

    if (["Otro"].includes(event.target.value)) {
      slideDown(target);
      this.refusalPersonContactedOtherTarget.setAttribute("required", "");
    } else {
      slideUp(target);
      this.refusalPersonContactedOtherTarget.removeAttribute("required");
      this.refusalPersonContactedOtherTarget.value = "";
    }
  }

  handleLossToFollowUpReason(event) {
    let target = document.getElementById(event.target.dataset.div);

    if (["Otro"].includes(event.target.value)) {
      slideDown(target);
      this.lossToFollowUpReasonReasonOtherTarget.setAttribute("required", "");
    } else {
      slideUp(target);
      this.lossToFollowUpReasonReasonOtherTarget.removeAttribute("required");
      this.lossToFollowUpReasonReasonOtherTarget.value = "";
    }
  }

  handleLossToFollowUpPersonContacted(event) {
    let target = document.getElementById(event.target.dataset.div);

    if (["Otro"].includes(event.target.value)) {
      slideDown(target);
      this.lossToFollowUpPersonContactedOtherTarget.setAttribute(
        "required",
        ""
      );
    } else {
      slideUp(target);
      this.lossToFollowUpPersonContactedOtherTarget.removeAttribute("required");
      this.lossToFollowUpPersonContactedOtherTarget.value = "";
    }
  }

  handleReferredTo(event) {
    let target = document.getElementById(event.target.dataset.div);

    if (["Otro"].includes(event.target.value)) {
      slideDown(target);
      this.referredToOtherTarget.setAttribute("required", "");
    } else {
      slideUp(target);
      this.referredToOtherTarget.removeAttribute("required");
      this.referredToOtherTarget.value = "";
    }
  }

  handleTransferredTo(event) {
    let hospital = document.getElementById("transferred-hospital");
    let service = document.getElementById("transferred-service");

    if (["Transferido a Otro Hospital"].includes(event.target.value)) {
      slideDown(hospital);
      slideUp(service);
      this.transferredHospitalTarget.setAttribute("required", "");
      this.transferredServiceTarget.removeAttribute("required");
      this.transferredServiceTarget.value = "";
    } else {
      slideDown(service);
      slideUp(hospital);
      this.transferredServiceTarget.setAttribute("required", "");
      this.transferredHospitalTarget.removeAttribute("required");
      this.transferredHospitalTarget.value = "";
    }
  }

  handleDeceasedReason(event) {
    let target = document.getElementById(event.target.dataset.div);

    if (["Otro"].includes(event.target.value)) {
      slideDown(target);
      this.deceasedReasonOtherTarget.setAttribute("required", "");
    } else {
      slideUp(target);
      this.deceasedReasonOtherTarget.removeAttribute("required");
      this.deceasedReasonOtherTarget.value = "";
    }
  }
}
