import { Controller } from "stimulus";
import { slideDown, slideUp } from "./utils";

export default class extends Controller {
  static targets = ["examTypeOther", "sampleOther"];

  handleExamTypeOther(event) {
    const input = this.examTypeOtherTarget.querySelector("input");

    if (["Otro"].includes(event.target.value)) {
      slideDown(this.examTypeOtherTarget);
      input.setAttribute("required", "");
    } else {
      slideUp(this.examTypeOtherTarget);
      input.removeAttribute("required");
      input.value = "";
    }
  }

  handleSampleOther(event) {
    const input = this.sampleOtherTarget.querySelector("input");

    if (["Otro"].includes(event.target.value)) {
      slideDown(this.sampleOtherTarget);
      input.setAttribute("required", "");
    } else {
      slideUp(this.sampleOtherTarget);
      input.removeAttribute("required");
      input.value = "";
    }
  }
}
