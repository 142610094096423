import "choices.js/public/assets/scripts/choices.min.js";
import "choices.js/public/assets/styles/choices.min.css";

const nombreDeEnfermedad = () => {
  const enfermedades = document.querySelector(
    ".nombre-de-enfermedad-js-choice"
  );
  if (enfermedades) {
    const enfermedadesChoices = new Choices(enfermedades, {
      removeItemButton: true,
      shouldSort: false,
      noResultsText: "No se encontró busqueda",
      searchResultLimit: 15,
    });
  }
};

const addChoices = () => {
  const choices = document.querySelectorAll(".add-js-choices");

  if (choices.length > 0) {
    choices.forEach((choice) => new Choices(choice));
  }
};

const addChoicesWithRemove = () => {
  const choices = document.querySelectorAll(".add-js-choices-with-remove");

  if (choices.length > 0) {
    choices.forEach(
      (choice) =>
        new Choices(choice, {
          removeItemButton: true,
        })
    );
  }
};

document.addEventListener("turbolinks:load", () => {
  nombreDeEnfermedad();
  addChoices();
  addChoicesWithRemove();
});
