import Rails from "@rails/ujs";
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["form", "cancelButton"];

  initialize() {
    this.showCancel = false;

    if (this.showCancel == false && this.hasCancelButtonTarget) {
      this.cancelButtonTarget.style.display = "none";
    }
  }

  handleSearch() {
    if (
      this.formTarget.getElementsByTagName("input")[0].value.length > 0 &&
      this.hasCancelButtonTarget
    ) {
      this.cancelButtonTarget.style.display = "block";
    } else {
      this.cancelButtonTarget.style.display = "none";
    }

    Rails.fire(this.formTarget, "submit");
  }

  handleClear() {
    this.formTarget.querySelector("input.form-control[type=text]").value = "";
    this.cancelButtonTarget.style.display = "none";

    Rails.fire(this.formTarget, "submit");
  }
}
